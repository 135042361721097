@import url("https://pro.fontawesome.com/releases/v5.2.0/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300&family=Oswald:wght@200;400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");
.navbar {
  --bs-navbar-nav-link-padding-x: 1rem;
}
.navbar-nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
}
* {
  // transition: 0.5s all;
  user-select: none;
}

@font-face {
  font-family: "Overpass-Medium";
  src: url("../fonts/Overpass/Overpass-Medium.ttf");
}

@font-face {
  font-family: " Editors-Note";
  src: url("../fonts/Editors-Note/Editor'sNote-Medium.ttf");
}
:root{
  --swiper-navigation-sides-offset:-60px; /* offset for navigation */
}

body {
  // font-family: "Overpass", sans-serif;
  &.modal-open #root {
    filter: blur(10px);
  }
  // .card-img,
  // .card-img-bottom {
  //   border-top-center-radius: 7px;
  //   border-bottom-left-radius: 7px;
  // }

  .challenge_live.badge {
    background-color: rgb(229, 9, 125);
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 15px;
    padding: 5px 10px;
    color: white;
    position: absolute;
    top: 0px;
    right: 0px;
    text-transform: uppercase;
  }
  .btn-default {
    background-color: #48277f;
    border: 1px solid #48277f;
    border-radius: 25px;
    color: #fff;
    display: inline-block;

    text-decoration: none;
    padding: 6px 25px;

    &:hover {
      transition: 0.5s all;
      background-color: #fff;
      color: #48277f;
      border-color: #48277f;
    }
  }
  .btn-default-2 {
    background-color: #e5097d;
    border: 1px solid #e5097d;
    width: 230px;
    text-decoration: none;
    border-radius: 55px;
    height: 60px;
    color: #fff;
    display: inline-block;
    padding: 19px 25px;
    text-align: center;

    &:hover {
      transition: 0.5s all;
      background-color: #fff;
      color: #e5097d;
      border-color: #e5097d;
    }
  }
  .btn-default-3 {
    background-color: #fff;
    color: #e5097d;
    border: 1px solid #e5097d;
    width: 230px;
    text-decoration: none;
    border-radius: 55px;
    height: 60px;
    text-align: center;
    padding: 19px 25px;
    display: inline-block;

    &:hover {
      transition: 0.5s all;
      color: #fff;
      background-color: #e5097d;
      border: 1px solid #e5097d;
    }
  }
  .form-control {
    &:hover,
    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }
}
ul#main_menu .dropdown-menu {
  position: static !important;
  border-radius: 0;
  transform: unset !important;
  // opacity: 0;
  // visibility: hidden !important;
  // height: 0;
  // padding: 0;
  // transition: .3s all;
}

.dropdown-menu.show {
  // opacity: 1;
  // visibility: visible !important;
  // height: auto;
  // padding: 0.5rem 0;
  // transition: .3s all;
}
ul#main_menu > li > a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  border-bottom: 1px solid #fbfbfb;
}
ul#main_menu > li:last-child > a {
  border-bottom: 0;
}
ul#main_menu > li:last-child > .dropdown-menu {
  border-top: 1px solid #fbfbfb;
}
ul#main_menu .dropdown-menu {
  // background: linear-gradient(179deg, #15bd98 36.03%, #0fabd6 104.15%);
  border: 0;
  padding: 0;
  background-color: transparent;
}

ul#main_menu .dropdown-menu > li > a {
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid #fbfbfb;
  /* padding: 0 0.5em; */
}

ul#main_menu li a:hover,
ul#main_menu li a:focus,
ul#main_menu li a:active,
ul#main_menu li a.active,
ul#main_menu li.show ul.show li a:hover,
ul#main_menu li.show ul.show li a:focus,
ul#main_menu li.show ul.show li a:active,
ul#main_menu li.show ul.show li a.active {
  color: #ffffff;
  background-color: #e5097d;
}
ul#main_menu li.show ul.show {
  background-color: #e5097d;
}
ul#main_menu li.show ul.show li a {
  color: #48277f;
}
button[type="submit"] {
  width: 300px;
  height: 50px;
  font-size: 16px;
  border-color: transparent;
  background-color: #e5097d;
  background-repeat: no-repeat;
  border-radius: 10px;
  text-shadow: 0 0 6px #000000eb;
  &:hover {
    color: #000;
  }
}
.form_add_user {
  label.form-label {
    color: #969696;
  }

  input::placeholder,
  select::placeholder,
  [class*="-placeholder"] {
    color: #000 !important;
  }
  button[type="submit"] {
    width: 300px;
    height: 50px;
    font-size: 20px;
    border-color: transparent;
    background: #e40d7d;
    background-repeat: no-repeat;
    border-radius: 10px;
  }
}

.upload__image-wrapper {
  // width: max-content;
  // height: max-content;
  // border: 1px solid #e51a84;
  // border-radius: 50%;
  position: relative;
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
}

.d-flex {
  .upload__image-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #e40d7d;
  }
  .upload__image-wrapper {
    button {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      bottom: -2px;
      right: 4px;
    }
  }
  .image-item {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.image-item__btn-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
}

.image-item__btn-wrapper button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.upload__image-wrapper button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.upload__image-wrapper button {
  background-color: #e40d7d;
  color: #fff;
  border: 0;
}

.image-item img {
  // max-width: 100%;
  // // height: 200px;
  // // border-radius: 50%;
  // object-fit: cover;
  object-fit: contain;
  height: 150px;
  // width: 100%;
}

.rdw-editor-main {
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
}

.rdw-editor-toolbar {
  justify-content: center;
}

.rdw-dropdown-selectedtext {
  color: #15bd98;
  text-decoration: none;
}

.rdw-option-wrapper[aria-selected="true"] {
  background-color: #15bd98;
  border-color: #15bd98;
  color: #fff;
}

.rdw-option-wrapper[aria-selected="true"] img {
  filter: invert(1);
}
table#faqsTable.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: #fff;
}

table#faqsTable tbody tr td:last-child {
  width: 56px;
  vertical-align: middle;
}

div#admin_profile .dropdown-menu {
  // background: linear-gradient(179deg, #15bd98 36.03%, #0fabd6 104.15%);
  border: 0;
  padding: 0;
  background: #48277f;
}

div#admin_profile button.dropdown-toggle {
  background: transparent;
  color: #fff;
}

div#admin_profile button.dropdown-toggle::after {
  border-top-color: #000;
}

#main_content > nav .profile-details {
  justify-content: flex-end !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #e5097d;
}
#main_content > nav div#admin_profile .dropdown-menu li a {
  padding: 8px 0;
  color: #fff;
  line-height: normal;
  border-color: #fff;
  height: auto;
}

#main_content > nav div#admin_profile .dropdown-menu li {
  padding: 0 8px;
  height: auto;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
}

#main_content > nav div#admin_profile .dropdown-menu .dropdown-item:focus a,
#main_content > nav div#admin_profile .dropdown-menu .dropdown-item:hover a {
  color: #e5097d;
}

.sidebar {
  position: fixed;
  height: 100%;
  flex-direction: column;
  width: 240px;
  // background: linear-gradient(179deg, #15bd98 36.03%, #0fabd6 104.15%);
  background: #48277f;
  transition: all 0.5s ease;
  &.active {
    // width: 0px;
  }

  ul#main_menu {
    flex-grow: 1;
    height: auto;
    overflow-y: scroll;
    margin: 0;
    width: 100%;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #e5097d;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: 5px solid #fff;
      background: #fff;
    }
    li {
      a {
        font-weight: 700;
      }
    }
  }

  .logo-details {
    // flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
    background-color: #fff;
    .logo_name {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .nav-links {
    margin-top: 10px;
    flex-direction: column;
    li {
      position: relative;
      list-style: none;
      height: 50px;
      &:last-child {
        .links_name {
          color: #fe7272;
        }
      }
      a {
        img {
          height: 24px;
          width: 24px;
          margin-right: 12px;
        }
        height: 100%;
        display: flex;
        padding-left: 23px;
        width: 100%;
        align-items: center;
        text-decoration: none;
        transition: all 0.4s ease;
        &:hover {
          background: #276fb7;
        }
        .links_name {
          color: #fff;
          font-size: 15px;
          white-space: nowrap;
          text-transform: capitalize;
          font-weight: 700;
        }
      }
      a.active {
        background: #276fb7;
      }
      i {
        min-width: 60px;
        text-align: center;
        font-size: 18px;
        color: #fff;
      }
    }
  }
  .copyright_para {
    // flex-grow: 1;
    color: #e5097d;
    background-color: #fff;
    // position: absolute;
    // bottom: 10px;
    text-align: center;
    font-size: 10px;
    width: 100%;
    margin: 0;
    padding: 10px 0;
  }
}
#main_content {
  position: relative;
  background: #f5f5f5;
  min-height: 100vh;
  width: calc(100% - 240px);
  left: 240px;
  transition: all 0.5s ease;
  &.active {
    left: 0px;
    width: 100%;
    & > nav {
      left: 0px;
      width: 100%;
    }
  }
  & > nav {
    display: flex;
    justify-content: space-between;
    height: 80px;
    background-color: white;
    display: flex;
    align-items: center;
    // position: fixed;
    // width: calc(100% - 240px);
    // left: 240px;
    z-index: 100;
    padding: 0 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    .sidebar-button {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 500;
      img {
        margin-right: 10px;
      }
      a {
        text-decoration: none;
        color: #e40d7d;
        font-weight: 700;
        // ---------------------------------------------
      }
    }
    .search-box {
      position: relative;
      height: 50px;
      max-width: 550px;
      width: 100%;
      margin: 0 20px;
    }
    .profile-details {
      display: flex;
      align-items: center;
      height: 50px;
      // min-width: 190px;
      padding: 0 15px 0 2px;
      .dropdown-toggle {
        padding: 0;
        border: 0;
        &:focus,
        &:active,
        &:hover {
          outline: 0;
          box-shadow: none;
        }
      }
      .dropdown-menu {
        padding: 0;
        flex-direction: column;
        li {
          position: relative;
          list-style: none;
          height: 50px;
          &:first-child {
            a {
              border-top-left-radius: 0.375rem;
              border-top-right-radius: 0.375rem;
            }
          }
          &:last-child {
            a {
              border-bottom-left-radius: 0.375rem;
              border-top-center-radius: 0.375rem;
              border-bottom: 0;
            }
          }
          a {
            border-bottom: 1px solid #276fb7;
            &.active {
              background: #276fb7;
              .links_name {
                color: #fff;
              }
            }
            img {
              height: 24px;
              width: 24px;
              margin-right: 9px;
              // filter: invert(37%) sepia(89%) saturate(588%) hue-rotate(173deg) brightness(85%) contrast(91%);
            }
            height: 100%;
            display: flex;
            padding-left: 23px;
            width: 100%;
            align-items: center;
            text-decoration: none;
            transition: all 0.4s ease;
            &:hover {
              // background: #276fb7;
            }
            .links_name {
              color: #276fb7;
              font-size: 15px;
              font-weight: 400;
              white-space: nowrap;
              text-transform: capitalize;
            }
          }
        }
      }
      img {
        height: 40px;
        width: 40px;
        border-radius: 6px;
        object-fit: cover;
      }
      .admin_name {
        font-size: 15px;
        font-weight: 500;
        color: #333;
        margin: 0 10px;
        white-space: nowrap;
      }
    }
  }
  .home-content {
    position: relative;
    padding-top: 104px;
    .sales-boxes {
      .top-sales {
        margin: 0;
      }
    }
  }
  main {
    .articles {
      padding: 50px 0px;
      // height: 100vh;
      // justify-content: center;
      // display: flex;
      .vlogs {
        background: white;
        padding: 22px 30px;
        border-radius: 5px;
        align-items: center;
        text-align: end;
        justify-content: space-between;
        display: flex;
        .imageCover {
          // padding: 10px;
          background: #e93393;
          border-radius: 50%;
          min-width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 30px;
            object-fit: contain;
            height: 30px;
          }
        }
        h3 {
          text-align: start;
          color: #e93393;
        }
        .para {
          color: #15cf74;
          font-size: 12px;
          font-weight: 500;
          span {
            color: #000 !important;
            padding: 3px;
          }
        }
        .para2 {
          color: #c31162;
          font-size: 12px;
          font-weight: 500;
          span {
            color: #000 !important;
            padding: 3px;
          }
        }
        .para3 {
          color: #48277f;
          font-size: 12px;
          font-weight: 500;
          span {
            color: #000 !important;
            padding: 3px;
          }
        }
      }
    }

    .table_section {
      background-color: white;
      // padding-bottom: 100px;
      table {
        width: 100%;
        margin: 0px auto;
        thead {
          tr {
            th {
              text-align: left;
              padding: 20px;
              font-weight: 600;
              background: #276fb7;
              color: #fff;
              border: 1px solid #d9d9d9;
            }
          }
        }
        tbody {
          tr {
            background: #f4fdff;
            border-bottom: 1px solid #d9d9d9;
            margin-bottom: 5px;
            &:nth-child(even) {
              background: #ffffff;
              th {
                background: #fafafa;
              }
            }
            td {
              text-align: left;
              padding: 20px;
              font-weight: 300;
            }
            th {
              text-align: left;
              padding: 20px;
              font-weight: 600;
              background: #e4faff;
              color: #606060;
              // border: 1px solid #d9d9d9;
            }
          }
        }
        tfoot {
          tr {
            background: none;
            td {
              padding: 10px 2px;
              font-size: 0.8em;
              font-style: italic;
              color: #8a97a0;
            }
          }
        }
      }

      .pagination {
        margin-top: 30px;
        a {
          padding: 8px 16px;
          text-decoration: none;
          transition: background-color 0.3s;
          border: 1px solid #ddd;
          color: black;
          float: left;
          padding: 8px 16px;
          text-decoration: none;
          justify-content: center;
          margin-right: 5px;
          &:first-child {
            border: 0;
            color: #276fb7;
          }
          &:last-child {
            border: 0;
            color: #276fb7;
          }
          &:hover {
            &:not(.active) {
              background-color: #ddd;
            }
          }
        }
        a.active {
          color: white;
          border: 1px solid #276fb7;
          background-color: #276fb7;
          color: white;
          border: 1px solid;
        }
        justify-content: center;
      }
      .table_footer {
        margin-top: 100px;
        padding: 25px;
        background-color: #ecedf3;
        p {
          text-align: right;
          color: #606060;
          margin: 0;
          font-weight: 500;
        }
      }
    }

    section#search_section {
      display: flex;
      justify-content: flex-end;
      padding: 10px 23px;
      background-color: #ecedf3;
      form {
        color: #555;
        display: flex;
        background-color: white;
        width: 230px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;

        input[type="search"] {
          border: none;
          background: transparent;
          margin: 0;
          padding: 7px 8px;
          font-size: 14px;
          color: inherit;
          border: 1px solid transparent;
          border-radius: inherit;
          &::placeholder {
            color: #bbb;
          }
          &:focus {
            box-shadow: 0 0 3px 0 #1183d6;
            border-color: #1183d6;
            outline: none;
          }
        }
        button[type="submit"] {
          text-indent: -999px;
          overflow: hidden;
          padding: 7px;
          border: 1px solid transparent;
          border-radius: inherit;
          background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
            no-repeat center;
          cursor: pointer;
          opacity: 0.7;
          &:hover {
            opacity: 1;
          }
          &:focus {
            box-shadow: 0 0 3px 0 #1183d6;
            border-color: #1183d6;
            outline: none;
          }
        }
      }
    }
    section.user_profile_section {
      background-color: #fff;
      padding: 10px 0 30px 0;

      .card > img {
        width: 50%;
        padding: 35px 0px;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
      }

      .btn-upload {
        margin: 0px auto;
        width: 60%;
        height: 45px;
        align-items: center;
        justify-content: center;
        background: #13273a;
        border-radius: 5px;
        color: white;
        font-size: 14px;
      }
      .btn-larg {
        background-color: #276fb7;
        color: white;
        padding: 13px 1px;
        justify-content: center;
        width: 100%;
        font-size: 14px;
        border-radius: 5px;
      }
      .card-title1 {
        margin-bottom: 10px;
      }

      .card-title {
        justify-content: center;
        text-align: center;
        font-size: 16px;
      }

      .row2 {
        padding: 20px;
        background: #f8f8f8;
        border-bottom: 1px solid #d9d9d9;
      }
      .card-title2 {
        color: #276fb7;
        font-weight: 700;
        font-size: 26px;
      }
      .form-label {
        font-weight: 500;
        font-size: 16px;
      }

      .form-control,
      .form-select {
        /*		background-color:#D9D9D9;*/
        height: 45px;
        background-color: rgba(236, 237, 243, 0.27);
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        color: #c7c7c7;
      }

      .form-control1 {
        background-color: white;
        border: 0;
      }
      .card {
        background-color: rgba(236, 237, 243, 0.27);
        border: rgba(236, 237, 243, 0.27);
      }
      .card-footer {
        background-color: white;
        border: 0;
      }
      .card-title3 {
        color: #606060;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
@media (max-width: 1150px) {
  #main_content {
    width: 100% !important;
  }
}
@media (max-width: 700px) {
  #main_content > nav .sidebar-button img {
    width: 70%;
    margin-right: 0;
  }
  #main_content > nav {
    padding: 0 5px;
  }
  nav {
    .sidebar-button {
      .dashboard {
        // display: none;
        font-size: 16px;
      }
    }
    .profile-details {
      .admin_name {
        display: none;
      }
      i {
        display: none;
      }
    }
  }
}
@media (max-width: 550px) {
  .sidebar.active {
    ~ {
      .home-section {
        nav {
          .profile-details {
            display: none;
          }
        }
      }
    }
  }
}
#header {
  .top-header {
    background-color: #48277f;

    ul {
      li {
        a {
          font-size: 16px;
          color: #fff;
          font-weight: 700;
          text-transform: uppercase;

          &:hover {
            color: #e5097d;
          }
        }
      }
    }

    .header-style {
      color: #fff;
      padding: 8px 16px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;

        i {
          color: #e5097d;
          padding: 0 10px;
        }
      }
    }
  }
  .mid-header {
    padding: 20px 0;

    ul {
      li {
        a {
          font-size: 16px;
          color: #1d1d1b;
          font-weight: 700;
          text-transform: uppercase;
          width: max-content;
          &.btn-default {
            border-radius: 5px;
            color: #fff;
          }

          &:hover {
            color: #48277f;

            i {
              color: #48277f;
            }
          }
        }

        a.dropdown-item {
          margin: 7px 0;
          background-color: #e5097d;
          color: white;
          border: 1px solid #e40d7d;

          &:hover {
            border: 1px solid #48277f;
            color: white;
            background-color: #48277f;
          }
        }

        .item-1 {
          background-color: #48277f !important;
          color: white;
          border: 1px solid #48277f !important;

          &:hover {
            border: 1px solid #e40d7d !important;
            color: white;
            background-color: #e40d7d !important;
          }
        }
      }
    }
  }
  .text-end {
    ul {
      li {
        a {
          margin-right: 60px;
          background-color: #e5097d;
          margin-bottom: 5px;
          border-radius: 5px;
          font-size: 13px;
          padding: 5px 19px;
          color: white;
          i {
            font-size: 8px;
          }
        }
      }
    }
  }
  .bottom-header {
    background-color: #e5097d;

    ul {
      justify-content: center;

      li {
        a {
          font-family: "Roboto Mono", monospace;
          text-transform: uppercase;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          word-spacing: -3px;
          border-bottom: 1px solid transparent;
          &:hover {
            color: #48277f;
            border-bottom: 1px solid #48277f;
          }
        }
      }

      .dropdown {
        .dropdown-menu {
          min-width: 30rem;

          li {
            a {
              color: #000;
              border: 0;
              color: #000;
              border-radius: 8px;
              word-spacing: -4px;
              &:hover {
                background-color: #48277f !important;
                color: #fff;
                border-radius: 8px;
                transition: 1s;
              }
            }
          }
        }
      }
    }
  }
  .bottom-header2 {
    background-color: #e5097d;
    display: none;
    ul {
      justify-content: center;
      & > li {
        & > a {
          font-family: "Roboto Mono", monospace;
          text-transform: uppercase;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          word-spacing: -3px;
          border-bottom: 1px solid transparent;
          white-space: nowrap;

          &:hover {
            color: #48277f;
            border-bottom: 1px solid #48277f;
          }
        }
      }
    }
    .dropdown-menu {
      position: absolute;
      a {
        color: #000;
      }
    }
  }
}
// .modal {
//   .modal-content {
//     width: 75%;
//     margin: 0 auto;
//     border: 0;
//     padding: 39px 10px;
//   }
//   .modal-body {
//     display: flex;
//     justify-content: center;

//     input {
//       border: 1px solid #e5097d;
//       &:focus {
//         box-shadow: none;
//       }
//     }
//     label {
//       font-size: 20px;
//     }
//   }
//   .modal-footer {
//     justify-content: center;
//     .btn-default-1 {
//       border: 1px solid #e5097d;
//       border-radius: 25px;
//       color: #e5097d;
//       width: 30%;
//       font-size: 16px;
//       font-weight: 600;

//       &:hover {
//         transition: 0.5s all;
//         background-color: #fff;
//         color: #fff;
//         border-color: #48277f;
//         background-color: #48277f;
//       }
//     }
//     .btn-default2 {
//       background-color: #e5097d;
//       border: 1px solid #e5097d;
//       border-radius: 25px;
//       color: #fff;
//       width: 30%;
//       font-size: 16px;
//       font-weight: 600;

//       &:hover {
//         transition: 0.5s all;
//         color: #48277f;
//         border-color: #48277f;
//         background-color: transparent;
//       }
//     }
//   }
// }
#main,
#main_2 {
  .ad_sec {
    text-align: center;
    padding: 50px 0;
    padding-bottom: 0;
    img{
      width: 80%;
    }
  }

  .trending_sec {
    padding: 30px 0;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 43px;

      text-align: center;
      font-family: "Overpass-Medium";
      text-transform: uppercase;

      margin-bottom: 30px;
      a {
        text-decoration: none;
        color: #1d1d1b;
        font-family: "Overpass-Medium";
      }
      i {
        color: #e5097d;
      }
    }

    #trendingPostTabs {
      border: none;
      margin-bottom: 20px;

      li {
        margin: 0 10px;

        a {
          border: none;
          font-weight: 100;
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 28px;
          text-align: center;
          text-transform: uppercase;
          color: #1d1d1b;

          &:hover,
          &.active {
            color: #e5097d;
          }
        }
      }
    }

    #trendingPostTabsContent {
      .popular_sec {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  .popular_sec {
    padding: 30px 0;
    background-color: #fff0f7;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 43px;
      text-align: center;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      color: #1d1d1b;
      margin-bottom: 30px;
      a {
        text-decoration: none;
        font-family: "Overpass-Medium";
        text-transform: uppercase;
        color: #1d1d1b;
      }
      i {
        color: #e5097d;
      }
    }

    .card {
      border: 0;
      height: auto;
      background-color: transparent;
      border-radius: 0;

      .card-body {
        .post_info {
          text-align: center;

          .post_cat {
            a {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #e5097d;
              padding-right: 5px;
              text-decoration: none;

              &:hover {
                color: #48277f;
              }
            }
          }

          .post_created_on {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: #828282;
            position: relative;
            padding-left: 45px;

            &::before {
              content: "";
              height: 1px;
              width: 40px;
              background-color: #828282;
              position: absolute;
              top: 9px;
              left: 0;
            }
          }
        }

        .post_title {
          text-align: center;

          a {
            font-family: "Noto Serif", serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            color: #1d1d1b;
            text-decoration: none;

            &:hover {
              color: #e5097d;
            }
          }
        }

        .post_author_info_cover {
          align-items: center;

          .post_author_info {
            padding-right: 10px;
            display: flex;
            align-items: center;

            .author_img {
              margin-right: 10px;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              border: 2px solid #e5097d;
            }

            .author_name {
              a {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                color: #1d1d1b;
                text-decoration: none;

                &:hover {
                  color: #e5097d;
                }
              }
            }
          }

          .post_stats {
            padding-left: 10px;

            .views,
            .comments,
            .likes {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              text-transform: uppercase;
              color: #1d1d1b;

              i {
                padding: 0 10px 0 3px;
              }
            }
          }
        }
      }

      &.card_style_2 {
        flex-direction: row;
        margin-bottom: 20px;

        .card-img-top {
          height: 150px;
          object-fit: contain;
          border: 1px solid #e62984;
          max-width: 40%;
          border-radius: 5px !important;
        }

        .card-body {
          .post_info {
            text-align: left;
          }

          .post_title {
            text-align: left;
          }

          .post_author_info_cover {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .categories_sec {
    padding: 30px 0;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 43px;
      text-align: center;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      color: #1d1d1b;
      margin-bottom: 50px;
      img {
        margin-right: 10px;
      }
      i {
        color: #e5097d;
      }
    }

    .item {
      text-align: center;

      img {
        width: 50%;
        display: block;
        margin: 0 auto 25px;
      }

      h4 {
        a {
          font-family: "Noto Serif", serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          color: #1d1d1b;
          text-decoration: none;

          &:hover {
            color: #e5097d;
          }
        }
      }
    }
  }

  .creators_sec {
    background-color: #eaf1f3;
    padding: 30px 0;

    h1 {
      font-style: normal;
      padding: 0 0 50px;
      font-weight: 700;
      font-size: 28px;
      line-height: 43px;
      text-align: center;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      color: #1d1d1b;
      a {
        text-decoration: none;
        font-family: "Overpass-Medium";
        text-transform: uppercase;
        color: #1d1d1b;
      }
    }

    .card {
      background-color: transparent;
      border: none;
      img {
        height: 85px;
        width: 85px;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid #e5097d;
      }
      .card-body {
        .card-title {
          // font-weight: 400;
          // font-size: 29px;
          // line-height: 45px;
          // color: #000000;
          font-family: "Noto Serif", serif;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 18px;
          a {
            text-decoration: none;
            color: #000;
            &:hover {
              color: #e5097d;
            }
          }
        }

        .card-text {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
          text-transform: capitalize;
          color: #e5097d;
        }

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          text-transform: uppercase;
          color: #000000;

          .post,
          .follower,
          .following {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            padding-right: 22px;
          }

          span {
            font-style: normal;
            font-weight: 400;
            padding-right: 6px;
            font-size: 12px;
            line-height: 15px;
            text-transform: capitalize;
            color: #000000;
          }
        }
      }
    }
  }

  //contact
  .sec1 {
    padding: 25px 0;
    padding-bottom: 20px;

    .text-center {
      h1 {
        font-weight: 700;
        font-size: 25px;
        font-family: "Overpass-Medium";
      }
    }
  }

  .sec3 {
    padding: 50px;

    .states {
      a {
        background: #e5097d;
        padding: 20px;
        border-radius: 50%;

        .w-37 {
          width: 30%;
        }
      }
    }

    h4 {
      font-size: 35px;
      font-weight: 500;
    }

    .bg {
      padding-bottom: 40px;

      p {
        font-family: "Noto Serif", serif;
        font-size: 18px;
        font-weight: 400;
      }
    }

    .last {
      h4 {
        font-family: "Noto Serif", serif;
      }
    }

    .gb {
      padding-bottom: 40px;

      p {
        font-family: "Noto Serif", serif;
        font-size: 18px;
        font-weight: 400;
        margin: 0;
      }
    }

    .pt-5 {
      padding-bottom: 30px !important;
      font-family: "Noto Serif", serif;

      p {
        font-size: 14px;
        font-weight: 400;
      }

      h1 {
        font-size: 38px;
        font-weight: 400;
      }
    }

    .fac {
      width: 40%;
    }

    .inputs {
      .form-control {
        border: none;
        border-bottom: 1px solid #000000;
        border-radius: 0;
        box-shadow: none;
      }
    }

    .btn-default {
      text-decoration: none;
    }
  }

  //FAQ`S
  .faq {
    padding: 30px 0;

    h1 {
      font-weight: 700;
      font-size: 25px;
      font-family: "Overpass-Medium";
    }

    p {
      font-size: 17px;
      font-weight: 400;
    }
  }

  .faq-2 {
    padding: 50px 0;
    padding-top: 0;
    font-family: "Noto Serif", serif;

    .d-flex {
      padding: 18px;
      align-items: center;
      background-color: #eaf1f3;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      justify-content: space-between;

      h2 {
        margin: 0;
        font-size: 20px;
      }

      .igm {
        background-color: #48277f;
        padding: 12px;
        font-size: 0;
        align-items: center;
        border-radius: 50%;
      }
    }

    .bor {
      border-radius: 0;
      border-bottom: 1px solid #c1d4d9;
    }

    .para {
      border: 1px solid #eaf1f3;
      padding: 10px;

      p {
        font-size: 16px;
      }
    }

    .brd {
      padding-left: 30px;

      h5 {
        font-size: 25px;
        font-weight: 400;
      }

      .text-center {
        padding: 40px 0;
        background-color: #f5f5f5;

        .first {
          background-color: #eaeaea;
          display: inline-block;
          padding: 34px;
          border-radius: 50%;

          img {
            width: 87%;
          }
        }
      }

      .heading {
        text-align: left;
        padding-top: 16px;
        padding-left: 25px;

        h3 {
          padding-left: 19px;
        }
      }

      .email {
        display: flex;
        justify-content: center;

        .input-group {
          width: 80% !important;

          .form-control {
            padding: 13px;
          }
        }
      }

      .bnt {
        a {
          text-decoration: none;
          border: none !important;
          background: #222222;
          color: #fff;
          padding: 10px 136px;
        }
      }
    }
  }

  //full blog page
  .blog {
    padding: 50px 0;
    padding-bottom: 0 !important;

    .sec_contant {
      .hin {
        font-size: 35px;
        font-weight: 400;
        font-family: "Noto Serif", serif;
      }
    }

    .date {
      p {
        font-size: 16px;
        gap: 2px;
        span {
          align-self: center;
          text-align: center;
        }
      }

      .space {
        padding: 0 6px;
      }
    }
  }

  .bannar {
    padding-bottom: 10px;
  }

  .mid {
    padding: 0 0 30px 0;

    .padding {
      padding: inherit;
    }

    ul {
      li {
        margin: 0 5px;

        .facebook {
          background-color: #3b5998;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          border: 2px solid transparent;
          color: #fff;
        }

        .facebook:hover {
          background-color: transparent;
          color: #3b5998;
          border: 2px solid #3b5998;
        }

        .twitter {
          background-color: #000;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #fff;
          border: 2px solid transparent;
        }

        .twitter:hover {
          background-color: transparent;

          border: 2px solid #000;
        }

        .linkedin {
          background-color: #1178b3;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          border: 2px solid transparent;
          color: #fff;
        }

        .linkedin:hover {
          background-color: transparent;
          color: #1178b3;
          border: 2px solid #1178b3;
        }

        .whatsapp {
          background-color: #15cf74;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #fff;
          border: 2px solid transparent;
        }

        .whatsapp:hover {
          background-color: transparent;
          color: #15cf74;
          border: 2px solid #15cf74;
        }

        .share {
          background-color: #000000;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #fff;
          border: 2px solid transparent;
        }

        .share:hover {
          background-color: transparent;
          color: black;
          border: 2px solid #000;
        }

        .box {
          background-color: #48277f;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #fff;
          border: 2px solid transparent;
        }

        .box:hover {
          background-color: transparent;
          color: #48277f;
          border: 2px solid #48277f;
        }

        .cloud {
          background-color: #e40d7d;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #fff;
          border: 2px solid transparent;
        }

        .cloud:hover {
          background-color: transparent;
          color: #e40d7d;
          border: 2px solid #e40d7d;
        }

        .dropdown-toggle {
          background-color: #e40d7d;
          border: none;
          color: #fff;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .mid2 {
    .p1 {
      margin: 0;
      font-size: 17px;
    }

    .container {
      .hin {
        font-size: 35px;
        font-weight: 400;
        font-family: "Editor's Note", serif;
      }
    }

    p {
      font-size: 17px;
      font-weight: 400;
    }

    .tag {
      h3 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);
        font-family: "Noto Serif", serif;
      }
    }
  }

  .mid3 {
    padding: 50px 0;

    .bol {
      background-color: #eaf1f3;
      padding: 20px;
    }
  }

  .mid4 {
    padding-bottom: 10px;

    .container {
      display: flex;
      justify-content: center;
    }

    .btn-default {
      font-family: "Overpass-Medium";
      background-color: #e5097d;
      // padding: 17px 63px;
      font-size: 1.2rem;
      border-radius: 55px;
      color: #fff;
      border: 0;
    }

    .tint {
      padding: 0px 10px 0 0;

      a {
        text-decoration: none;
        border: 1px solid #d9d9d9;
        color: black;
        padding: 9px 20px;
        border-radius: 18px;
      }
    }
  }

  .boder {
    p {
      color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    }
  }

  .mid5 {
    padding: 8px 0;

    .btn-cover {
      justify-content: space-between;

      .btn1 {
        justify-content: start;
        display: flex;

        a {
          background-color: #e40d7d;
          color: #fff;
          text-decoration: none;
          padding: 10px 20px;
          border-radius: 24px;
        }
      }

      .btn2 {
        justify-content: end;
        display: flex;

        a {
          background-color: #e40d7d;
          color: #fff;
          text-decoration: none;
          padding: 10px 20px;
          border-radius: 24px;
        }
      }
    }
  }

  .mid6 {
    padding: 25px 0 0;

    h3 {
      font-size: 20px;
      margin: 0;
      font-family: "Noto Serif", serif;
      a {
        text-decoration: none;
        color: #000;
      }
    }

    .bl {
      border-right: 1px solid #b6b6b6;
    }

    .gap {
      padding-left: 25px;
    }
  }

  .mid7 {
    padding: 0 0 20px 0;

    .card {
      display: flex;
      justify-content: center;
      flex-direction: row;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-top: 0;

      .card-body {
        .jappa {
          a {
            color: #e5097d;
            text-decoration: none;
            font-size: 17px;
            font-weight: 700;
          }
        }

        .Poruka {
          padding: 10px 0 0 0;

          p {
            font-size: 16px;
            font-weight: 700;
          }
        }

        .days {
          p {
            color: #828282;
            font-size: 14px;
          }

          i {
            padding-right: 10px;
          }
        }

        .d-flex {
          color: #828282;

          .like {
            padding-right: 30px;
            font-size: 17px;
          }

          .reply {
            a {
              font-size: 17px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .m-5 {
      padding-top: 20px !important;
      margin: 0 !important;
    }
    .ui.comments .comment .avatar img,
    .ui.comments .comment img.avatar {
      height: 2.3em;
      width: 2.3em;
      border-radius: 50%;
      border: 2px solid #e5097d;
    }
  }

  .commentForm {
    padding: 0 0 40px 0;
    h5 {
      font-size: 19px;
      font-weight: 500;
    }
    .commentFormCover {
      img {
        margin-right: 10px;
        // height: 60px;
        // width: 60px;
        border-radius: 50%;
        border: 2px solid #e5097d;
      }
      .commentFormInputCover {
        background: #f6f6f6;
        padding: 10px;
        border-radius: 35px;

        .input-group {
          .form-control {
            border: none;
            border-radius: 0;
            background: #f6f6f6;
            &:focus {
              box-shadow: none;
            }
          }
        }

        .commentFormSubmit {
          border-style: none;
          background-color: #d54058;
          text-decoration: none;
          padding: 10px 50px;
          color: white;
          font-weight: 500;
          border-radius: 30px;
          font-size: 17px;
        }
      }
    }
  }

  //Advertisement
  .banner2 {
    padding: 25px 0;

    h1 {
      font-weight: 700;
      font-size: 34px;
      line-height: 43px;
      align-items: center;
      padding: 0 0 10px;
      text-align: center;
      text-transform: uppercase;
      color: #1d1d1b;
    }

    img {
      padding: 0 0 20px;
    }

    h2 {
      font-family: "Noto Serif", serif;
      font-weight: 400;
      font-size: 27px;
      line-height: 54px;
      color: #1d1d1b;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      color: #000000;
      span {
        background-color: transparent !important;
      }
    }
  }

  .info_1 {
    padding: 25px 0;

    .back {
      background: #eaf1f387;
      padding: 25px;
      border-radius: 30px;

      h1 {
        font-size: 35px;
        font-weight: 400;
      }

      .row {
        padding: 20px 0 15px 0;

        .col-lg-6 {
          padding-left: 35px;

          .input-group {
            .form-control {
              border: 0;
              border-radius: 0;
              border-bottom: 1px solid;
              padding: 12px 0;
              background-color: transparent;
            }
          }

          .imformation {
            padding: 32px 0 0 0;

            h5 {
              font-size: 20px;
              font-weight: 600;
            }

            .d-flex {
              p {
                padding-left: 15px;
              }
            }
          }
        }
      }

      .alink {
        text-align: center;
        padding: 30px 0;

        a {
          text-decoration: none;
          background-color: #e5097d;
          border-radius: 25px;
          padding: 14px 125px;
          color: #fff;
        }
      }
    }
  }

  //prosonal info

  .my_account_sec {
    padding: 50px 0;
    background-color: #f5f5f5;

    .account_menu {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      padding: 27px 20px;
      background: white;
      border-radius: 6px;
      height: fit-content;

      .acount {
        color: #828282;

        h4 {
          font-size: 22px;
          font-weight: 300;
        }
      }

      .btn-default {
        width: 100%;
        margin-top: 20px;
      }

      .nav {
        .nav-item {
          .nav-link {
            font-size: 16px;
            border-bottom: 1px solid #d9d9d9;
            border-radius: 0;
            font-weight: 400;
            background-color: transparent;
            color: #d9d9d9 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 8px;
            .icon_cover {
              width: 30px;
              padding-right: 5px;
            }
            i {
              color: #d9d9d9;
              font-size: 23px;
            }

            span {
              i {
                width: 45px;
              }
            }

            &.active {
              color: #000 !important;

              i {
                color: #e5097d;
                font-size: 23px;
              }
            }
          }
        }
      }
    }

    .litf {
      background-color: white !important;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      padding: 20px;

      .mt-2 {
        padding: 0 8px;
      }

      h5 {
        padding-left: 10px;
      }

      .form.label {
        font-size: 14px;
        color: #000;
      }

      .form-control {
        height: 49px;
        color: #000;
      }

      .form-select {
        height: 49px;
        color: #000;
      }

      .form-control1 {
        padding-top: 13px;
        padding-bottom: 10px;
        color: #606060;
      }

      .form-check-label {
        color: #828282;
      }
    }

    .para {
      color: #e5097d;
      border: 1px solid #e5097d;
      padding: 12px 20px 0 20px;
      border-radius: 4px;

      h6 {
        font-weight: 400;
        color: #e5097d;
      }

      p {
        font-size: 13px;
        color: #333333;

        a {
          color: #00a0ff;
          text-decoration: none;
        }
      }
    }

    .button {
      text-align: center;
      padding: 36px 0px 34px 0;

      a {
        text-decoration: none;
        color: white;
        border-radius: 73px;
        padding: 24px 130px;
        background-color: #e5097d;
      }
      button[type="submit"] {
        width: auto;
        height: auto;
        font-size: inherit;
        text-decoration: none;
        color: white;
        border-radius: 73px;
        padding: 24px 130px;
        background-color: #e5097d;
        text-shadow: none;
      }
    }

    .payment {
      padding: 20px 0 0 0;

      h1 {
        color: #1d1d1b;
        text-align: center;
        font-size: 25px;
        font-family: "Overpass-Medium";
      }

      .button {
        text-align: center;
        padding: 36px 0px 34px 0;

        a {
          text-decoration: none;
          color: white;
          border-radius: 73px;
          padding: 18px 82px;
          background-color: #e5097d;
        }
      }

      .bank-transfer {
        padding: 8px 0 8px;

        h5 {
          text-align: center;
          font-size: 22px;
          font-weight: 600;
        }
      }
    }

    .top {
      border-top: 1px solid #d9d9d9;
    }

    .allow {
      text-decoration: none;
      color: #828282;
      font-size: 15px;
    }

    .color:checked {
      background-color: #c1c1c1;
      border: 0;
      font-size: 29px;
    }

    .color {
      background-color: #c1c1c1;
      border: 0;
      font-size: 29px;
    }

    .color {
      background-color: #e5097d;
    }

    .withdraw {
      h6 {
        font-size: 25px;
        font-family: "Overpass-Medium";
      }

      .top_withdraw {
        padding-bottom: 20px;

        .coin {
          border: 1px solid #d9d9d9;
          border-right: 0;
          border-radius: 4px;
          padding: 10px 12px;
          border-top-right-radius: 0;
          border-top-center-radius: 0;

          a {
            font-family: Poppins;
            text-decoration: none;
            color: #828282;
            font-size: 24px;
            font-weight: 400;
          }
        }

        .available {
          border: 1px solid #d9d9d9;
          border-left: 0;
          padding: 13px 12px;
          border-radius: 4px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          p {
            font-size: 20px;
            font-weight: 400;
            font-family: Overpass;
          }

          span {
            color: #d9d9d9;
          }
        }
      }

      .mid_withdraw {
        label {
          color: #000;
          font-size: 15px;
          font-weight: 700;
          width: 90%;
          // padding-left: 50px;
        }

        .d-flex {
          p {
            text-align: end;
            padding-right: 55px;
            margin: 0;
          }
        }

        .dry {
          width: 100% !important;

          .form-control {
            border-right: 0;
            border-top-right-radius: 0;
            border-top-center-radius: 0;

            &:focus {
              outline: none;
              border: 1px solid #d9d9d9 !important;
              box-shadow: none;
              border-right: 0 !important;
            }
          }
        }

        .erase {
          width: 100% !important;

          input {
            border-left: 0;
            text-align: end;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:focus {
              outline: none;
              border: 1px solid #d9d9d9 !important;
              box-shadow: none;
              border-left: 0 !important;
            }
          }
        }

        .input-group {
          width: 90%;
        }

        .pt-4 {
          display: flex;
          justify-content: center;
          p {
            width: 92%;
          }
        }

        #notice {
          display: flex;
          justify-content: center;

          p {
            width: 92%;
            border: 1px solid #e5097d;
            border-radius: 5px;
            padding: 10px;
            color: #ec726d;
            font-size: 14px;
          }
        }
        button[type="submit"] {
          width: auto;
          height: auto;
          box-shadow: none;
          font-size: inherit;
          background-color: #48277f;
          border: 1px solid #48277f;
          border-radius: 25px;
          color: #fff;
          display: inline-block;
          text-decoration: none;
          padding: 6px 25px;

          &:hover {
            transition: 0.5s all;
            background-color: #fff;
            color: #48277f;
            border-color: #48277f;
          }
        }
      }
    }

    .history {
      h4 {
        text-align: center;

        font-weight: 700;
        font-size: 25px;
        font-family: "Overpass-Medium";
      }

      p {
        font-size: 14px;
        font-weight: 400;
        font-family: Overpass;
      }

      .table {
        thead {
          tr {
            th {
              background-color: #000;
              color: #fff;
            }
          }
        }

        tbody {
          border: 0px solid transparent !important;

          tr {
            border: 0px solid transparent !important;
            border-style: none !important;

            td {
              padding-bottom: 0;

              .m-0 {
                font-size: 14px;
                color: #000000;
                font-weight: 600;

                span {
                  color: #828282;
                  font-size: 13px;
                  font-weight: 400;
                }
              }

              a {
                text-decoration: none;
                color: #15cf74;
              }

              p {
                color: #828282;
              }
            }

            .td {
              .d-flex {
                i {
                  margin-right: 5px;
                  font-size: 20px;
                  font-weight: 500;
                }

                a {
                  text-decoration: none;
                  color: #15cf74;
                  font-size: 14px;
                  font-weight: 600;
                }

                p {
                  font-size: 11px;
                  color: #828282;
                }
              }
            }
          }
        }

        .b1 {
          td {
            background-color: #e5097d0d;
            padding-bottom: 0;

            .m-0 {
              font-size: 14px;
              color: #000000;
              font-weight: 600;

              span {
                color: #828282;
                font-size: 13px;
                font-weight: 400;
              }
            }

            a {
              text-decoration: none;
              color: #15cf74;
            }

            p {
              color: #828282;
            }
          }

          .td {
            .d-flex {
              i {
                margin-right: 5px;
                font-size: 20px;
                font-weight: 500;
              }

              a {
                text-decoration: none;
                color: #15cf74;
                font-size: 14px;
                font-weight: 600;
              }

              p {
                font-size: 11px;
                color: #828282;
              }
            }
          }
        }
      }
    }

    .topic {
      height: 365px;
      padding: 10px;
      overflow-y: scroll;
      overflow-x: hidden;

      .m-0 {
        padding-top: 7px;
        padding-bottom: 7px;
      }

      button {
        border: 1px solid rgba(229, 9, 125, 1);
        background-color: transparent;
        color: rgba(229, 9, 125, 1);
        border-radius: 3px;
        padding: 7px 25px;
        font-size: 14px;
      }

      .active {
        background-color: rgba(229, 9, 125, 1);
        color: white;
        border: 1px solid rgba(229, 9, 125, 1);
        border-radius: 3px;
        font-size: 14px;
        padding: 7px 25px;
      }
    }

    .change {
      h3 {
        font-size: 25px;
        font-family: "Overpass-Medium";
        font-weight: 600;
        margin: 0;
      }

      p {
        font-size: 21px;
        font-family: Overpass;
        font-weight: 400;
        color: #828282;
      }

      .row {
        label {
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-weight: 500;
          padding-left: 15px;
        }
      }
    }

    .earn {
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: 100% 80%;
      .btn-default {
        i {
          padding-right: 10px;
        }
      }
      h5 {
        text-align: center;

        font-weight: 600;
        font-size: 25px;
        font-family: "Overpass-Medium";
      }

      p {
        font-size: 14px;
      }

      .row {
        .d-flex {
          margin-left: 20px;

          .bordr {
            padding-left: 10px;
            background: #faf5f1;
            padding: 22px 14px 11px 1px;
            align-self: center;

            span {
              font-size: 30px;
              font-weight: 100;
              color: #e5097d;
            }

            a {
              img {
                width: 70%;
              }
            }
          }

          .align-self-center {
            background: #faf5f1;
            padding: 3px 3px 10px 14px;

            .m-0 {
              font-size: 18px;

              font-family: "Noto Serif", serif;
              font-weight: 400;
              color: #1d1d1b;
            }

            h6 {
              font-size: 26px;
              font-weight: 600;
              font-family: "Noto Serif", serif;
            }
          }
        }

        ul {
          list-style: none;

          li {
            position: relative;
            padding: 10px 0 50px 28px;
            font-size: 13px;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10px;
              width: 1px;
              height: 40px;
              background-color: #e5097d;
            }
            &:last-child {
              padding: 10px 0 10px 28px;
              &::after {
                display: none;
              }
            }
            &::before {
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #e5097d;
              position: absolute;
              left: 0;
              top: 10px;
            }
          }
        }
      }

      .lastt {
        ul {
          list-style: none;

          li {
            position: relative;
            padding: 10px 0 30px 20px;
            font-size: 20px;
            font-weight: 400;
            padding-left: 28px;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10px;
              width: 1px;
              height: 40px;
            }

            &::before {
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #e5097d;
              position: absolute;
              left: 0;
              top: 10px;
            }
          }
        }
      }
    }
  }

  //edittext
  .edittext {
    padding: 30px 0 50px;

    h1 {
      font-size: 28px;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
    }

    .exciting {
      border-radius: 9px 9px 0px 0px;
      background: #eaf1f3;
      padding: 31px 20px 20px;
      font-family: " Editors-Note";
      font-size: 25px;
      font-weight: 400;
    }
    .poem-toolbar {
      background: #eaf1f3;
      border-bottom: 1px solid #e5097d;
      padding-bottom: 10px;
      .rdw-option-wrapper {
        background: #eaf1f3;
      }
      .rdw-block-wrapper {
        background: #eaf1f3;
      }
      .rdw-dropdown-selectedtext {
        color: #e5097d;
        background: #eaf1f3;
        border: 1px solid #e5097d;
        border-radius: 5px;
      }
      .rdw-option-active {
        box-shadow: 1px 1px 0px #e5087d inset;
        background-color: #e5087d;
        border-color: #e5087d;
        color: #000000;
      }
    }
    .poem-editor {
      background: #eaf1f3;
      padding: 0;
    }
    .poem {
      border-radius: 9px 9px 0px 0px;
      background: #eaf1f3;
      padding-top: 31px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 100px;
      font-family: " Editors-Note";
      font-size: 25px;
      font-weight: 400;
    }
  }

  //404 error
  .error {
    padding: 50px 0;
    font-family: " Editors-Note";

    h1,
    h4 {
      color: #e5097d;
    }

    h1 {
      font-size: 52px;
      font-weight: 400;
    }

    h4 {
      font-size: 35px;
    }

    p {
      color: #000;
      font-size: 20px;
    }
  }

  //select text
  .select {
    .back {
      background-color: #f5f5f5;
      color: #828282;
      border: 0;
      padding: 20px 58px;
      width: 50%;
    }

    h1 {
      text-transform: uppercase;
      font-size: 25px;
    }

    .text {
      background-color: #48277f;
      color: white;
      padding: 20px 73px;
      width: 50%;
      border: 0;
    }

    .backround {
      background-color: #eaf1f3;
      padding: 17px;
      margin-top: 13px;

      .mb-6 {
        margin-bottom: 5rem;
      }

      .miden {
        background: white;
        padding: 20px;
        margin: 0;
        border-radius: 5px;
      }

      .btn {
        background: white;
      }
    }
  }
  //new-password
  .new-password {
    padding: 50px 0;

    .bod {
      border: 1px solid #00000036;
      border-radius: 5px;
      align-items: center;
      padding: 20px 40px;
      .forget {
        color: #e5097d;
        margin: 0;
        font-size: 37px;
      }

      p {
        color: #e5097d;
      }
      .back_login {
        color: #000;
        a {
          font-weight: 600;
          color: #000;
        }
      }
      .input-group {
        width: 65%;

        .form-control {
          padding: 10px 10px;
          position: relative;
          border-radius: 5px;
        }
      }

      .reset {
        width: 65%;

        .btn-default {
          width: 100%;
          border-radius: 5px;
          padding: 10px 10px;
          background-color: #e5097d;
          border: 0;
        }
      }
    }
  }
  //forget password
  .forget-password {
    padding: 50px 0;

    .bod {
      border: 1px solid #00000036;
      border-radius: 5px;

      .forget {
        color: #e5097d;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 600;
      }

      p {
        color: #626262;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }

      .otp-resend {
        p {
          color: #e5097d;
          text-align: center;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
        }

        button {
          background-color: transparent;
          border: none;
          color: #000;
        }
      }

      .back_login {
        color: #000;
        a {
          font-weight: 600;
          color: #000;
        }
      }
      .input-group {
        width: 65%;

        .form-control {
          padding: 10px 10px;
        }
      }

      .reset {
        width: 65%;

        .btn-default {
          width: 100%;
          height: 65px;
          border-radius: 10px;
          background-color: #e5097d;
          border: 0;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }

  //teams
  .teams {
    .row {
      .card {
        .card-body {
          .card-title {
            font-size: 25px;
            font-family: " Editors-Note";
          }

          .card-text {
            color: #e5097d;
            font-weight: 500;
            font-size: 19px;
          }
        }
        &.flipped {
          transform: rotateY(180deg);
        }
      }

      hr {
        color: #e5097d;
      }
    }

    .btn-default {
      color: white;
      background-color: #e5097d;
      border: 0;
      width: 19%;
      padding: 14px 0;
    }
  }

  //login
  .login {
    padding: 70px 0;
    .pb-5 {
      padding-bottom: 5rem !important;
    }
    .bod {
      border: 1px solid #00000036;
      border-radius: 4px;
      .signin {
        padding: 20px 50px;
      }
      .faceBookAuth {
        text-align: end;
        padding: 0;
        a {
          background-color: #507cbe;
          padding: 8px 16px;
          text-align: center;
          font-size: 15px;
          text-decoration: none;
          border-radius: 5px;
          color: white;

          i {
            font-size: 23px;
          }
        }
      }
      .Sign {
        color: #e5097d;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 600;
      }

      .access {
        font-size: 20px;
        color: #626262;
      }

      .input-group {
        // width: 89%;
        margin: 0 auto;
        // height: 14%;

        input {
          color: #828282;

          &::placeholder {
            color: #828282;
          }

          &:focus {
            box-shadow: none;
            border: 1px solid #828282;
          }
        }
      }

      .btn-default {
        width: 89%;
        border-radius: 5px;
        padding: 11px 20px;
        background-color: #e5097d;
        border: 1px solid #e5097d;
        color: white;
        text-transform: uppercase;
      }

      .forgetPassLink {
        a {
          color: #00a0ff;
          font-size: 17px;
        }
      }

      .googleAuth {
        a {
          background-color: #fff;
          padding: 8px 16px;
          font-size: 15px;
          border: 1px solid #8c8c8cab;
          text-decoration: none;
          border-radius: 5px;
          color: black;
          text-align: center;

          i {
            font-size: 23px;
          }
        }
      }
      p {
        color: #e5097d;

        b {
          color: #000;
        }
      }
    }
  }

  //top bloggers
  .top_bloggers {
    border-bottom: 1px solid #b6b6b6;

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: #b6b6b6;
          font-size: 15px;
          font-weight: 700;
          padding: 10px 20px;
          font-family: "Roboto Mono", monospace;
          &:hover {
            color: #e5097d;
            border-bottom: 1px solid;
            transition: 1s !important;
          }
        }
      }
    }
  }

  .topContributorsSection {
    padding: 50px 0;

    .rost {
      border: 1px solid #0000003d;
      border-radius: 11px;
    }

    .btn-default {
      background-color: #e5097d;
      border: 0;
    }

    p {
      font-size: 19px;
      color: #e5097d;
    }
  }

  //images listing
  .listing_sec {
    padding: 20px 0 0 0;
    .left-text {
      .active {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
      }
      a {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        // line-height: 18px;
        text-decoration: none;
        text-transform: uppercase;
        color: #e5097d;
      }
    }
    .sortByTogglerCover {
      display: flex;
      align-items: center;
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        // line-height: 22px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin: 0;
        padding: 0 15px 0;
      }
      .sortByToggler {
        display: flex;
        align-items: center;
        a {
          font-style: normal;
          font-weight: 700;
          font-size: 17px;
          // line-height: 22px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          text-decoration: none;
          color: rgba(33, 37, 41, 0.75);
          &.active {
            color: #e5097d;
          }
        }
        .seperator {
          margin: 0 7px;
          font-size: 20px;
          vertical-align: unset;
          display: flex;
          align-self: stretch;
          background: #000;
          width: 2px;
          height: 30px;
        }
      }
    }
    .infinite-scroll-component {
      overflow: hidden !important;
    }

    #listingtabcontent {
      .tab-pane {
        p {
          font-style: normal;
          font-weight: 700;
          font-size: 17px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          margin: 0;
          color: #000000;
        }

        .mid-tab {
          padding: 21px 0;

          .dropdown {
            .btn-dropdown {
              background-color: #f5f5f5;
              border-radius: 3px;
              padding: 5px 20px;
              margin: 0 0 0 20px;

              span {
                padding: 0 10px 0 0;
              }
            }
          }

          div {
            p {
              span {
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 22px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #e5097d;
                padding: 0 15px 0;
              }
            }
          }
        }
      }
    }
  }

  .img-listing {
    padding-bottom: 50px;

    .card {
      border: 0;
      height: auto;
      background-color: transparent;
      border-radius: 0;

      .card-body {
        .post_info {
          text-align: center;

          .post_cat {
            a {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #e5097d;
              padding-right: 5px;
              text-decoration: none;

              &:hover {
                color: #48277f;
              }
            }
          }

          .post_created_on {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
            color: #828282;
            position: relative;
            padding-left: 45px;

            &::before {
              content: "";
              height: 1px;
              width: 40px;
              background-color: #828282;
              position: absolute;
              top: 9px;
              left: 0;
            }
          }
        }

        .post_title {
          text-align: center;

          a {
            font-family: "Noto Serif", serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #1d1d1b;
            text-decoration: none;

            &:hover {
              color: #e5097d;
            }
          }
        }

        .post_author_info_cover {
          align-items: center;

          .post_author_info {
            padding-right: 10px;
            display: flex;
            align-items: center;

            .author_img {
              margin-right: 10px;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              border: 2px solid #e5097d;
            }

            .video_author_name {
              a {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                color: #1d1d1b;
                text-decoration: none;

                &:hover {
                  color: #e5097d;
                }
              }
            }
          }

          .post_stats {
            padding-left: 10px;
            display: flex;
            gap: 10px;
            .views,
            .comments,
            .likes {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              text-transform: uppercase;
              color: #1d1d1b;

              i {
                padding: 0 0px 0 3px;
              }
            }
          }
        }
      }

      &.card_style_2 {
        flex-direction: column;
        margin-bottom: 20px;

        .card-img-top {
          height: 270px;
          object-fit: contain;
          border: 1px solid #e62984;
        }

        .card-body {
          .post_info {
            text-align: left;
          }

          .post_title {
            text-align: left;
          }

          .post_author_info_cover {
            justify-content: flex-start;
            display: flex;
            // flex-direction: row !important;
          }
        }
      }
    }

    .btn-default {
      background-color: #e5097d;
      border: 0;
      color: white;
      padding: 19px 53px;
      border-radius: 55px;
    }
  }

  //upload video
  .upload-video-menu {
    background-color: #eaf1f387;

    ul {
      li {
        a {
          color: #1d1d1b;
          font-size: 13px;

          span {
            color: #222222;
          }
        }

        .disabled {
          color: #e5097d;
        }
      }
    }
  }

  .upload-video {
    padding: 50px 0;
    font-family: " Editors-Note";
    // h3 {
    //   span {
    //     color: #e5097d;
    //   }
    // }
    // h1 {
    //   font-size: 25px;
    //   font-family: "Overpass-Medium";
    //   font-weight: 700;
    // }
    // h4 {
    //   span {
    //     color: #e5097d;
    //   }
    // }
    .row {
      h2 {
        color: #1d1d1b;
        font-size: 23px;
        font-weight: 400;
        padding-left: 17px;

        span {
          color: #e5097d;
        }
      }

      .show-toolbar {
        background: #eaf1f3;
        border-bottom: 1px solid #e5097d;
        padding-bottom: 10px;
        .rdw-option-wrapper {
          background: #eaf1f3;
        }
        .rdw-block-wrapper {
          background: #eaf1f3;
        }
        .rdw-dropdown-selectedtext {
          color: #e5097d;
          background: #eaf1f3;
          border: 1px solid #e5097d;
          border-radius: 5px;
        }
        .rdw-dropdown-selectedtext {
          color: #e5097d;
          background: #eaf1f3;
          border: 1px solid #e5097d;
          border-radius: 5px;
        }
        .rdw-option-active {
          box-shadow: 1px 1px 0px #e5087d inset;
          background-color: #e5087d;
          border-color: #e5087d;
          color: #000000;
        }
      }
      .show-editor {
        // margin-top: -10px;
        padding: 0;
        background: #eaf1f3;
      }
      .show {
        background-color: #eaf1f3;
        padding: 17px;

        border-radius: 5px;
        font-size: 23px;
        color: #1d1d1ba3;
        h2 {
          padding: 0;
        }
      }
      .show-wrapper {
        background-color: #eaf1f3;
        padding: 17px;
        padding-bottom: 90px;
        border-radius: 5px;
        font-size: 23px;
        color: #1d1d1ba3;
        h2 {
          padding: 0;
        }
      }
    }

    .btn-default {
      font-family: "Overpass-Medium";
      background-color: #e5097d;
      // padding: 17px 63px;
      border-radius: 55px;
      color: #fff;
      border: 0;
    }

    .pb-5 {
      padding-bottom: 10% !important;
    }

    .dot {
      border: 2px dashed#C6C6C6;
      padding: 10px;

      h5 {
        font-size: 23px;
        padding-left: 10px;
      }

      p {
        font-family: "Overpass-Medium";
        color: #828282;
        padding-left: 10px;
        font-size: 18px;
      }
    }
  }

  .listing_sec {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 43px;
      text-align: center;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      color: #1d1d1b;
      margin-bottom: 30px;

      i {
        color: #e5097d;
      }
    }
    .btn-default {
      background-color: #e5097d;
      border-radius: 0px;
      border: 0;
      padding: 10px 30px;
      color: white;
      font-family: "Overpass-Medium";
    }
  }

  //100 word stories
  .word {
    padding-bottom: 50px;

    .card {
      box-shadow: 0px 4px 23px 0px #00000012;
      border: 0;

      h5 {
        a {
          color: #000;
          font-family: " Editors-Note";
          font-size: 22px;
          font-weight: 400;
        }
      }
      .card-body {
        a {
          color: #e5097d;
          font-family: "Overpass-Medium";
          text-decoration: none;
          font-size: 18px;
          font-weight: 500;

          i {
            color: #abaaaa;
            font-size: 23px;
            padding-left: 10px;
          }
        }
        .text-end {
          i {
            color: #abaaaa;
            font-size: 23px;
            padding-left: 10px;
          }
        }
      }
      a {
        i {
          color: #abaaaa;
          font-size: 23px;
          padding-left: 10px;
        }
      }

      .text-end {
        a {
          padding-right: 4px;
        }
      }

      .round {
        background-color: #e5097d;
        align-items: center;
        border-bottom-left-radius: 7px;
        border-top-center-radius: 7px;

        img {
          width: 14%;
        }
      }
    }
    .btn-default {
      background-color: #e5097d;
      width: 265px;
      height: 73px;
      border: 0;
      border-radius: 55px;
    }
  }
  //challenges listing
  .popular_sec_challenges {
    padding: 30px 0;

    .card {
      border: 0;
      height: auto;
      background-color: transparent;
      border-radius: 0;

      .card-body {
        .post_info {
          .post_cat {
            a {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #e5097d;
              padding-right: 5px;
              text-decoration: none;

              &:hover {
                color: #48277f;
              }
            }
          }

          .post_created_on {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: #828282;
            position: relative;
            padding-left: 45px;

            &::before {
              content: "";
              height: 1px;
              width: 40px;
              background-color: #828282;
              position: absolute;
              top: 7px;
              left: 0;
            }
          }
          .post_created_on_2 {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: #828282;
            position: relative;
          }
        }

        .post_title {
          a {
            font-family: " Editors-Note";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            color: #1d1d1b;
            text-decoration: none;

            &:hover {
              color: #e5097d;
            }
          }
        }

        .post_author_info_cover {
          align-items: center;
          display: flex;
          .post_author_info {
            padding-right: 10px;
            display: flex;
            align-items: center;

            .author_img {
              margin-right: 10px;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              border: 2px solid #e5097d;
            }

            .author_name {
              a {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                color: #1d1d1b;
                text-decoration: none;

                &:hover {
                  color: #e5097d;
                }
              }
            }
          }

          .post_stats {
            padding-left: 10px;
            display: flex;
            gap: 10px;
            .views,
            .comments,
            .likes {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              text-transform: uppercase;
              color: #1d1d1b;

              i {
                padding: 0 0px 0 3px;
              }
            }
          }
        }
      }

      &.card_style_2 {
        flex-direction: row;
        margin-bottom: 20px;

        .card-img-top {
          height: 250px;
          object-fit: contain;
          border: 1px solid #e62984;
          max-width: 40%;
        }

        .card-body {
          .post_info {
            text-align: left;
          }

          .post_title {
            text-align: left;
          }

          .post_author_info_cover {
            justify-content: flex-start;
          }
        }
      }
    }
    .btn-default {
      width: 255px;
      height: 73px;
      border-radius: 55px;
      background-color: #e5097d;
      border: 0;
    }
  }
  //select background
  .select_background {
    .back {
      background-color: #48277f;
      color: white;
      border: 0;
      padding: 20px 58px;
      width: 50%;
    }

    h1 {
      text-transform: uppercase;
      font-size: 25px;
    }

    .text {
      background-color: #f5f5f5;
      color: #828282;
      padding: 20px 73px;
      width: 50%;
      border: 0;
    }

    .backround {
      background-color: #eaf1f3;
      padding: 17px;
      margin-top: 13px;
      display: flex;
    }
    .btn-default_2 {
      border: 1px solid #828282;
      background-color: transparent;
      width: 230px;
      border-radius: 55px;
      height: 60px;
    }
    .btn-default {
      background-color: #e5097d;
      width: 230px;
      border-radius: 55px;
      height: 60px;
      border: 0;
    }
  }
  //publish
  .publish {
    padding: 90px 0 50px 0;
    p {
      font-weight: 400;
      font-size: 17px;
      text-transform: capitalize;
    }
    .btn-default {
      background-color: #e5097d;
      border: 0;
      width: 265px;
      height: 73px;
      border-radius: 55px;
    }
  }
  //select catorgies
  .add_story {
    padding: 50px 0;
    h1 {
      font-size: 25px;
      font-weight: 700;
      color: #1d1d1b;
      text-transform: uppercase;
      font-family: "Overpass-Medium";
    }
  }
  //categories
  .categories {
    padding: 50px 0;
    h1 {
      font-size: 25px;
      font-weight: 600;
      font-family: "Overpass-Medium";
    }
    .card {
      border: 0;
      .card-body {
        .card-title {
          font-size: 23px;
          font-weight: 400;
          text-decoration: none;
          p {
            font-family: " Editors-Note";
            color: #000000;
          }
        }
      }
    }
  }
  .submit {
    padding-bottom: 50px;
    .btn-default_2 {
      border: 1px solid #828282;
      background-color: transparent;
      width: 230px;
      border-radius: 55px;
      height: 60px;
    }
    .btn-default {
      background-color: #e5097d;
      width: 230px;
      border-radius: 55px;
      color: white;
      height: 60px;
      border: 0;
    }
  }
  //podcast
  .blog_2 {
    padding: 20px 0;
    background-color: #fff0f7;
    .sec_contant {
      .hin {
        font-size: 35px;
        font-weight: 400;
        font-family: " Editors-Note";
      }
    }

    .date {
      p {
        font-size: 16px;
      }

      .space {
        padding: 0 6px;
      }
    }
  }
  .bannar_2 {
    padding-bottom: 10px;
    padding: 5 0px 0;
    .border-1 {
      border: 1px solid #e5097d;
      border-radius: 3px;
      h2 {
        font-size: 20px;
        font-family: "Overpass-Medium";
        font-weight: 700;
      }
      p {
        font-size: 10px;
        font-weight: 400;
      }
      h5 {
        font-size: 15px;
        font-weight: 700;
        padding: 10px 0 0 0;
        span {
          font-size: 13px;
          font-weight: 400;
        }
      }
      .views {
        .share {
          background-color: #000000;
          padding: 0;
          display: flex;
          margin: 0 8px 0 0;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 35px;
          height: 35px;
          font-size: 14px;
          color: #fff;
          border: 2px solid transparent;
        }
        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .likes {
        i {
          font-size: 20px;
        }
      }
      .comments {
        font-size: 17px;
      }
    }
  }
  // .popular_sec_2 {
  //   padding: 30px 0;

  //   h1 {
  //     font-style: normal;
  //     font-weight: 700;
  //     font-size: 28px;
  //     line-height: 43px;
  //     text-align: center;
  //     font-family: "Overpass-Medium";
  //     text-transform: uppercase;
  //     color: #1d1d1b;
  //     margin-bottom: 30px;

  //     i {
  //       color: #e5097d;
  //     }
  //   }

  //   .card {
  //     border: 0;
  //     height: auto;
  //     background-color: transparent;
  //     border-radius: 0;

  //     .card-body {
  //       .post_info {
  //         .post_cat {
  //           a {
  //             font-style: normal;
  //             font-weight: 400;
  //             font-size: 14px;
  //             line-height: 18px;
  //             color: #e5097d;
  //             padding-right: 5px;
  //             text-decoration: none;

  //             &:hover {
  //               color: #48277f;
  //             }
  //           }
  //         }

  //         .post_created_on {
  //           font-style: normal;
  //           font-weight: 400;
  //           font-size: 17px;
  //           line-height: 18px;
  //           text-transform: uppercase;
  //           color: #828282;
  //           position: relative;
  //           padding-left: 70px;

  //           &::before {
  //             content: "";
  //             height: 1px;
  //             width: 65px;
  //             background-color: #828282;
  //             position: absolute;
  //             top: 7px;
  //             left: 0;
  //           }
  //         }
  //       }

  //       .post_title {
  //         a {
  //           font-family: " Editors-Note";
  //           font-style: normal;
  //           font-weight: 400;
  //           font-size: 17px;
  //           line-height: 26px;
  //           color: #1d1d1b;
  //           text-decoration: none;

  //           &:hover {
  //             color: #e5097d;
  //           }
  //         }
  //       }

  //       .post_author_info_cover {
  //         align-items: center;
  //         display: flex;

  //         .post_author_info {
  //           padding-right: 10px;
  //           display: flex;
  //           align-items: center;

  //           .author_img {
  //             margin-right: 10px;
  //             height: 30px;
  //             width: 30px;
  //           }

  //           .author_name {
  //             a {
  //               font-style: normal;
  //               font-weight: 400;
  //               font-size: 10px;
  //               line-height: 15px;
  //               text-transform: capitalize;
  //               color: #1d1d1b;
  //               text-decoration: none;

  //               &:hover {
  //                 color: #e5097d;
  //               }
  //             }
  //           }
  //         }

  //         .post_stats {
  //           padding-left: 10px;

  //           .views,
  //           .comments,
  //           .likes {
  //             font-style: normal;
  //             font-weight: 400;
  //             font-size: 10px;
  //             line-height: 18px;
  //             text-transform: uppercase;
  //             color: #1d1d1b;

  //             i {
  //               padding: 0 10px 0 3px;
  //             }
  //           }
  //         }
  //       }
  //     }

  //     &.card_style_2 {
  //       flex-direction: row;
  //       margin-bottom: 20px;

  //       .card-img-top {
  //         height: auto;
  //         object-fit: cover;
  //         max-width: 40%;
  //       }

  //       .card-body {
  //         .post_info {
  //           text-align: left;
  //         }

  //         .post_title {
  //           text-align: left;
  //         }

  //         .post_author_info_cover {
  //           justify-content: flex-start;
  //         }
  //       }
  //     }
  //   }
  // }
  .popular_sec_2 {
    padding: 30px 0;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 43px;
      text-align: center;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      color: #1d1d1b;
      margin-bottom: 30px;

      i {
        color: #e5097d;
      }
    }

    .card {
      border: 0;
      height: auto;
      background-color: transparent;
      border-radius: 0;

      .card-body {
        .post_info {
          .post_cat {
            a {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #e5097d;
              padding-right: 5px;
              text-decoration: none;

              &:hover {
                color: #48277f;
              }
            }
          }

          .post_created_on {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            text-transform: uppercase;
            color: #828282;
            position: relative;
            padding-left: 45px;

            &::before {
              content: "";
              height: 1px;
              width: 40px;
              background-color: #828282;
              position: absolute;
              top: 9px;
              left: 0;
            }
          }
        }

        .post_title {
          a {
            font-family: " Editors-Note";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 26px;
            color: #1d1d1b;
            text-decoration: none;

            &:hover {
              color: #e5097d;
            }
          }
        }

        .post_author_info_cover {
          align-items: center;
          display: flex;

          .post_author_info {
            padding-right: 10px;
            display: flex;
            align-items: center;

            .author_img {
              margin-right: 10px;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              border: 2px solid #e5097d;
            }

            .author_name {
              a {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                text-transform: capitalize;
                color: #1d1d1b;
                text-decoration: none;

                &:hover {
                  color: #e5097d;
                }
              }
            }
          }

          .post_stats {
            padding-left: 10px;
            display: flex;
            gap: 10px;
            .views,
            .comments,
            .likes {
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 18px;
              text-transform: uppercase;
              color: #1d1d1b;

              i {
                padding: 0 0px 0 3px;
              }
            }
          }
        }
      }

      &.card_style_2 {
        flex-direction: row;
        margin-bottom: 20px;

        .card-img-top {
          height: 270px;
          object-fit: contain;
          border: 1px solid #e62984;
          max-width: 40%;
        }

        .card-body {
          .post_info {
            text-align: left;
          }

          .post_title {
            text-align: left;
          }

          .post_author_info_cover {
            justify-content: flex-start;
          }
        }
      }
    }
  }
  //listing audio
  .Published {
    background-color: #eaf1f3;
    padding: 50px 0;
    h1 {
      font-size: 25px;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 40px;
      font-family: "Overpass-Medium";
      font-weight: 700;
    }
    .w-50 {
      width: 48% !important;
      img {
        width: 60%;
      }
    }
    h4 {
      font-size: 17px;
      font-weight: 700;
    }
    p {
      font-size: 10px;
      font-weight: 400;
    }
    td {
      font-size: 14px;
    }
    tbody {
      height: 40vh;
    }
    .pen {
      background: #15cf74;
      padding: 12px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .delete {
      background-color: #e32929;
      padding: 12px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .lished {
      a {
        text-decoration: none;
        color: black;
        background-color: #e5097d30;
        padding: 4px;
        border-radius: 61px;
        font-size: 14px;
      }
    }
  }
  //podcast page 3
  .userProfileSection {
    background-color: #eaf1f3;
    padding: 50px 0;
    .mobileProfileMenu {
      .col-6 {
        padding: 10px;
      }
      .col-lg-6 {
        padding: 10px 10px 10px 10px;
      }
      .col-lg-12 {
        padding: 10px 10px 10px 10px;
      }
      .card {
        border: 0;
        outline: 0;
        border-radius: 7px;
        background: #fff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.01);
        &.active {
          background: #e5097d;
          .card-body {
            .card-title {
              a {
                color: #fff;
              }
            }
          }
        }
        .card-body {
          .card-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .card-svg {
              // background-color: #e32975;
              border-radius: 50px;
              height: 60px;
              width: 60px;
              // padding: 10px;
              svg {
                height: 40px;
                width: 40px;
              }
            }
          }
          .card-title {
            text-align: center;
            padding: 20px 0 0px 0;
            a {
              font-weight: 700;
              font-size: 14px;
              text-decoration: none;
              color: #000;
            }
          }
        }
      }
    }
    .bg-light {
      background-color: white !important;
      box-shadow: 0px 4px 4px 0px #0000001f;
      border-radius: 3px;
      .profileImage {
        img,
        svg {
          border-radius: 50%;
          border: 2px solid #e5097d;
          width: 72px;
          height: 72px;
          object-fit: cover;
        }
      }
      .d-flex {
        padding: 20px 20px;
        .align-self-center {
          h1 {
            font-size: 12px;
            padding-left: 16px;
            font-weight: 600;
            font-family: "Overpass-Medium";
          }
        }
      }
      .justify-content-end {
        padding-right: 30px;
        .btn-default {
          background-color: #e5097d;
          border: 0;
          font-size: 18px;
          font-weight: 400;
          color: white;
        }
      }
      a {
        color: #828282;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .pt-5 {
      .nav-tabs {
        display: flex;
        border: 0;
        justify-content: space-around;
        background-color: white;
        align-items: center;
        box-shadow: 0px 2px 4px 0px #0000001f;

        li {
          flex: 1;
          border-right: 1px solid #dddddd;
          display: flex;
          justify-content: center;
          &.active {
            background-color: #e5097d;
          }
          &:last-child {
            border-right: 0;
          }
          button {
            border: 0;
            text-align: center;

            &:hover {
              border: 0;
              border-color: white;
            }
          }
        }
      }
    }
    .bannar_2 {
      padding-bottom: 10px;
      justify-content: center;
      display: flex;
      .border-1 {
        border: 1px solid #e5097d;
        background-color: white;
        border-radius: 3px;
        width: 85%;
        h2 {
          font-size: 20px;
          font-family: "Overpass-Medium";
          font-weight: 700;
        }
        p {
          font-size: 10px;
          font-weight: 400;
        }
        h5 {
          font-size: 15px;
          font-weight: 700;
          padding: 10px 0 0 0;
          span {
            font-size: 13px;
            font-weight: 400;
          }
        }
        .views {
          .share {
            background-color: #000000;
            padding: 0;
            display: flex;
            margin: 0 8px 0 0;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            width: 35px;
            height: 35px;
            font-size: 14px;
            color: #fff;
            border: 2px solid transparent;
          }
          span {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .likes {
          i {
            font-size: 20px;
          }
        }
        .comments {
          font-size: 17px;
        }
      }
    }
    .tab-pane {
      .bg-light {
        border: 1px solid #e5097d;
        border-radius: 7px;
      }
      // .row {
      //   a {
      //     color: black;
      //     text-decoration: none;
      //     padding: 2px 24px 2px 10px;
      //     align-items: end;
      //     display: flex;
      //     span {
      //       margin-left: 5px;
      //       font-size: 18px;
      //       font-weight: 400;
      //     }
      //   }
      // }
    }
    h2 {
      font-size: 22px;
      font-family: "Overpass-Medium";
      font-weight: 700;
    }
    h3 {
      color: #e5097d;
      font-size: 22px;
      font-weight: 700;
      font-family: "Overpass-Medium";
    }
    hr {
      border: 1px solid #d9d9d9;
    }
    p {
      color: #828282;
      font-weight: 500;
      font-family: "Overpass-Medium";
      font-size: 15px;
    }
    .card {
      border: 1px solid #e5097d;
      border-radius: 7px;
      align-items: center;
      // p {
      //   font-size: 8px;
      //   text-align: center;
      // }
      // h2 {
      //   text-align: center;
      //   font-size: 16px;
      // }
      // img {
      //   width: 85%;
      // }
      // .card-body {
      //   .views {
      //     .share {
      //       background-color: #000000;
      //       padding: 0;
      //       display: flex;
      //       margin: 0 8px 0 0;
      //       justify-content: center;
      //       align-items: center;
      //       border-radius: 25px;
      //       width: 35px;
      //       height: 35px;
      //       font-size: 14px;
      //       color: #fff;
      //       border: 2px solid transparent;
      //     }
      //     span {
      //       font-size: 18px;
      //       font-weight: 500;
      //     }
      //   }
      //   .likes {
      //     i {
      //       font-size: 20px;
      //     }
      //   }
      //   .comments {
      //     font-size: 17px;
      //   }
      //   h4 {
      //     font-weight: 700;
      //     font-size: 12px;
      //     span {
      //       font-weight: 400;
      //     }
      //   }
      // }
    }
    .tied {
      padding-top: 40px;

      .card {
        align-items: center;
        padding: 10px 18px;
        flex-direction: row;
        .w-50 {
          width: 38% !important;
        }
        .card-body {
          padding-bottom: 0;
          h3 {
            color: #000;
            font-size: 18px;
          }
          p {
            font-size: 10px;
            text-align: start;
          }
          h5 {
            font-size: 12px;
            margin: 0;
            text-align: end;
            font-weight: 700;
            span {
              font-weight: 400;
            }
          }
        }
      }
      .views {
        .share {
          background-color: #000000;
          padding: 0;
          display: flex;
          margin: 0 8px 0 0;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 35px;
          height: 35px;
          font-size: 14px;
          color: #fff;
          border: 2px solid transparent;
        }
        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .likes {
        i {
          font-size: 20px;
          color: black;
        }
      }
      .comments {
        font-size: 17px;
      }
    }
  }
  .para_img {
    p {
      font-size: 17px;
      b {
        text-transform: capitalize;
      }
    }
  }
  //advertise
  .advertise {
    .fresh {
      padding-left: 25%;
      h2 {
        color: #1d1d1b;
        font-size: 50px;
        font-family: " Editors-Note";
      }
      p {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
      }
      .btn-default {
        background-color: #e5097d;
        border: 0;
        width: 200px;
        height: 52px;
        border-radius: 50px;
      }
    }
  }
  .advertise_sec_2 {
    padding: 50px 0;
    h2 {
      color: #1d1d1b;
      font-size: 42px;
      text-align: center;
      font-family: " Editors-Note";
      font-style: normal;
      font-weight: 400;
    }
    p {
      color: #000;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
    }
    h6 {
      padding-left: 10px;
      color: #1d1d1b;
      font-size: 25px;
      font-family: " Editors-Note";
      font-style: normal;
      font-weight: 400;
    }
  }
  .advertise_sec_3 {
    padding: 50px 0;
    h1 {
      font-size: 40px;
      text-align: center;
      font-weight: 400;
      padding-bottom: 40px;
      font-family: " Editors-Note";
    }
    .card {
      border: 0;
      .card-body {
        padding-left: 0;
        .card-title {
          font-family: " Editors-Note";
          font-size: 22px;
          margin-bottom: 25px;
        }
        .card-text {
          text-transform: capitalize;
        }
      }
    }
  }
  .advertise_sec_4 {
    padding: 50px;
    h1 {
      font-size: 30px;
      padding-bottom: 20px;
      font-family: " Editors-Note";
      text-align: center;
    }
  }
  .advertise_sec_5 {
    padding: 50px 0;
    h1 {
      font-size: 30px;
      font-family: " Editors-Note";
      text-align: center;
      padding-bottom: 30px;
    }
    input {
      border: 1px solid #0b0b0b;
      border-radius: 0;
      &::placeholder {
        color: #515151;
      }
    }
    .mit {
      background-color: #e5097d;
      border: 0;
      color: white;
    }
  }
  //preview
  .preview {
    padding: 20px 0;

    text-align: center;
    h1 {
      text-transform: uppercase;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
    }
  }
}
#aboutus_main {
  .banner {
    padding: 30px 0;

    div {
      h1 {
        font-style: normal;
        font-weight: 700;

        line-height: 43px;
        text-transform: uppercase;
        font-size: 25px;
        font-family: "Overpass-Medium";
        color: #1d1d1b;
      }
    }

    .aboutus_heading {
      font-weight: 400;
      font-size: 42px;
      padding: 0 0 25px;
      line-height: 54px;
      font-family: "Noto Serif", serif;
      text-transform: capitalize;
      color: #1d1d1b;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 152.6%;
      text-transform: capitalize;
      color: #000000;
    }
  }

  .followus_sec {
    padding: 20px 0;

    h1 {
      font-family: "Noto Serif", serif;
      font-weight: 400;
      font-size: 42px;
      line-height: 54px;
      color: #1d1d1b;
    }

    ul {
      li {
        margin: 0 5px;

        .facebook {
          background-color: #3b5998;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          border: 2px solid transparent;
          color: #fff;
        }

        .facebook:hover {
          background-color: transparent;
          color: #3b5998;
          border: 2px solid #3b5998;
        }

        .twitter {
          background-color: #00aced;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #fff;
          border: 2px solid transparent;
        }

        .twitter:hover {
          background-color: transparent;
          color: #00aced;
          border: 2px solid #00aced;
        }

        .linkedin {
          background-color: #1178b3;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          border: 2px solid transparent;
          color: #fff;
        }

        .linkedin:hover {
          background-color: transparent;
          color: #1178b3;
          border: 2px solid #1178b3;
        }

        .whatsapp {
          background-color: #15cf74;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #fff;
          border: 2px solid transparent;
        }

        .whatsapp:hover {
          background-color: transparent;
          color: #15cf74;
          border: 2px solid #15cf74;
        }
      }
    }
  }
}

#privacy_policy_main {
  .privacy_policy_sec {
    padding: 15px 0;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      font-family: "Overpass-Medium";
      padding: 15px 0 0px;
      line-height: 43px;
      align-items: center;
      text-align: center;

      text-transform: uppercase;
      color: #1d1d1b;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 152.6%;
      text-transform: capitalize;
      color: #000000;
    }

    h2 {
      font-style: normal;
      font-family: "Noto Serif", serif;
      font-weight: 400;
      font-size: 35px;
      line-height: 45px;
      color: #1d1d1b;
    }
  }
}
#Terms_conditions_main {
  .Terms_conditions_sec {
    padding: 30px 0;

    h1 {
      font-weight: 700;
      padding: 0 0 10px;
      line-height: 43px;
      text-align: center;
      font-size: 25px;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      color: #1d1d1b;
    }

    p {
      font-weight: 400;
      font-size: 17px;
      padding: 8px 0px 2px;
      line-height: 152.6%;
      text-transform: capitalize;
      color: #000000;
    }

    h2 {
      font-weight: 400;
      font-size: 35px;
      line-height: 45px;
      color: #1d1d1b;
      font-family: "Noto Serif", serif;
    }

    .last_para {
      padding: 25px 0 0;
    }
  }
}
#listing_main {
  .listing_sec {
    padding: 8px 0;
    border-bottom: 1px solid gray;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 43px;
      text-align: center;
      font-family: "Overpass-Medium";
      text-transform: uppercase;
      color: #1d1d1b;
      margin-bottom: 30px;

      i {
        color: #e5097d;
      }
    }
    ul {
      justify-content: center;
      border: none;

      li {
        a {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          border: none;
          text-transform: uppercase;
          color: #b6b6b6;
          &:hover,
          &.active {
            color: #e5097d;
          }
        }
      }
    }
    .btn-default {
      background-color: #e5097d;
      border: 1px solid #e5097d;
      border-radius: 5px;
      color: #fff;
      padding: 6px 25px;

      &:hover {
        transition: 0.5s all;
        background-color: #fff;
        color: #e5097d;
        border-color: #e5097d;
      }
    }

    .tab-pane {
      .left-text {
        .breadcrumb-item a {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          text-transform: uppercase;
          color: #e5097d;
          text-decoration: none;
        }
      }

      p:not(.forChallenge) {
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin: 0;
        color: #000000;
      }

      .mid-tab {
        padding: 21px 0;

        .dropdown {
          .btn-dropdown {
            background-color: #f5f5f5;
            border-radius: 3px;
            padding: 5px 20px;
            margin: 0 0 0 20px;

            span {
              padding: 0 10px 0 0;
            }
          }
        }

        div {
          p {
            span {
              font-style: normal;
              font-weight: 700;
              font-size: 17px;
              line-height: 22px;
              display: flex;
              align-items: center;
              text-transform: uppercase;
              color: #e5097d;
              padding: 0 15px 0;
            }
          }
        }
      }

      #card-padding {
        .card {
          border-radius: 1px;
          padding: 15px;
          .card-body {
            padding-left: 15px !important;
            padding: 0;
            padding-bottom: 15px;
          }
        }
      }
      .card {
        .card-body {
          h5 {
            font-style: normal;
            font-weight: 400;
            font-family: "Noto Serif", serif;
            font-size: 20px;
            line-height: 23px;
            color: #1d1d1b;
            margin-bottom: 15px;
            a {
              text-decoration: none;
              text-transform: capitalize;
              color: #1d1d1b;
              &:hover {
                color: #e5097d;
              }
            }
          }
          .card-img {
            height: 130px;
            object-fit: cover;
          }
          .card-text {
            height: 50px;
          }
          .post_author_info_cover {
            display: flex;
            flex-direction: column-reverse;
            .post_stats {
              display: flex;
              gap: 10px;
              .views,
              .comments,
              .likes {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                text-transform: uppercase;
                color: #1d1d1b;

                i {
                  padding: 0 0px 0 3px;
                }
              }
            }
            .post_author_info {
              padding-right: 10px;
              display: flex;
              align-items: center;
              .author_img {
                margin-right: 10px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                border: 2px solid #e5097d;
              }
            }

            .author_name a {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;

              padding-left: 5px;
              line-height: 15px;
              text-transform: capitalize;
              color: #1d1d1b;
              text-decoration: none;
            }
          }
        }

        p:not(.forChallenge) {
          font-style: normal;
          font-weight: 400;
          margin: 0 0 12px;
          font-size: 12px;
          line-height: 160%;
          align-items: center;
          text-align: justify;
          text-transform: capitalize;
          color: #595959;
        }

        div {
          .card-btn {
            background-color: #e5097d;
            border-radius: 30px 0px;
            color: #fff;
            padding: 7px 20px;
            font-weight: 400;
            border: 1px solid #e5097d;
            &:hover {
              background-color: transparent;
              color: #e5097d;
            }
          }
        }
      }
    }
  }
}
#footer {
  background-color: #48277f;

  .widgets {
    padding: 49px 0 36px;

    h1 {
      font-style: normal;
      text-align: center;
      font-weight: 700;
      font-size: 25px;
      line-height: 43px;
      text-transform: uppercase;
      color: #ffffff;
    }

    ul {
      margin: 10px 0 0;
      justify-content: center;
      display: flex;
      align-items: center;

      li {
        a {
          margin: 0 7px;
          width: 50px;
          height: 50px;
          background-color: #ffffff;
          border-radius: 40px;
          justify-content: center;
          display: flex;
          align-items: center;
          font-size: 24px;

          i {
            color: #e32975;
          }
        }
      }
    }
    .w-90 {
      width: 90%;
    }
    .footer_style {
      padding: 20px 0 0;

      .button-1 {
        border: 2px solid #fff;
        padding: 9px 9px;
        border-radius: 14px;
        color: #fff;
        display: flex;

        i {
          font-size: 55px;
          color: #ffffff;
        }

        p {
          margin: 0;
          padding: 0 0 0 15px;
          font-size: 14px !important;

          span {
            font-size: 30px;
          }
        }
      }

      .button-2 {
        border: 2px solid #fff;
        padding: 9px 9px;
        border-radius: 14px;
        color: #fff;
        display: flex;

        i {
          font-size: 55px;
          color: #ffffff;
        }

        p {
          margin: 0;
          padding: 0 0 0 15px;
          font-size: 14px !important;

          span {
            font-size: 30px;
          }
        }
      }
    }
  }

  .copyright {
    border-top: 1px solid #271b39;
    padding: 25px 0 18px;

    ul {
      li {
        a {
          font-size: 12px;
          padding: 9px;
          color: #fff;
          font-weight: 700;
          text-transform: uppercase;

          &:hover {
            color: #e5097d;
          }
        }
      }
    }

    p {
      margin: 0;
      text-align: right;
    }
  }

  .site-logo {
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    text-decoration: none;
    padding-left: 25px;
  }

  .social {
    margin-top: 25px;

    li {
      margin: 0 5px;

      a {
        width: 70px;
        padding: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        font-size: 16px;
        background-color: #fff;
        border-radius: 50%;
        color: #e32975;
        border: 1px solid #fff;
        transition: 0.5s all;

        &:hover {
          transition: 0.5s all;

          background-color: transparent;
          border-radius: 50%;
          color: #fff;
          border-color: #fff;

          i {
            color: #fff;
          }
        }

        i {
          font-size: 35px;
          color: #e32975;
        }
      }
    }
  }

  p {
    color: #fff;
    font-size: 15px;
  }

  .left-site {
    display: flex;

    a {
      border: 2px solid #fff;
      border-radius: 13px;
      padding: 9px 14px 4px 79px;
      color: #fff;

      i {
        font-size: 65px;
        margin-left: -65px;
        color: #fff;
      }

      h2 {
        margin-top: -70px;
        padding-left: 15px;

        span {
          font-size: 15px;
        }
      }
    }
  }
}
// .modal {
//   .modal-dialog {
//     .modal-content {
//       .modal-header {
//         position: relative;

//         .modal-title {
//           font-size: 30px !important;
//           border-bottom: 1px solid;
//         }

//         .btn-close {
//           position: absolute;
//           top: 14px;
//           right: 12px;
//         }
//       }
//     }
//   }
// }
@media (max-width: 1200.98px) {
  #header .bottom-header ul li a {
    font-size: 14px;
  }
  footer#footer .copyright ul li a {
    font-size: 11px;
  }
}
@media (max-width: 1024.98px) {
  #header .bottom-header ul li a {
    font-size: 14px;
  }
  footer#footer .copyright ul li a {
    font-size: 10px;
  }
}
@media (max-width: 991.98px) {
  #main .my_account_sec .earn,
  #main_2 .my_account_sec .earn {
    background-position: 100% 60%;
  }
  // #header .bottom-header2 {
  //   display: block ;
  // }
  // #header .bottom-header{
  //   display: none;
  // }
  footer#footer .widgets ul li a {
    margin-bottom: 8px;
  }
  #main .login .bod .face {
    text-align: center;
  }
  #main .login .bod .google {
    background-color: #fff;
    /* padding: 16px 20px; */
    font-size: 15px;
    border: 1px solid rgba(140, 140, 140, 0.6705882353);
    text-decoration: none;
    border-radius: 5px;
    padding: 10px 52px;
    color: black;
    width: 92%;
    display: inline-block;
  }
  #main .login .bod p {
    color: #e5097d;
    font-size: 14px !important;
  }
  #header .top-header ul li a {
    font-size: 13px;
  }
  .child {
    display: none;
  }
  #main .userProfileSection .tab-pane .bg-light {
    margin-bottom: 10px;
  }
  #main .userProfileSection .pt-5 .nav-tabs li button img {
    padding-left: 16px;
    width: 103%;
  }
  .row_2 {
    .d-flex {
      flex-direction: column;
    }
  }
  .bullet {
    justify-content: space-between;
  }

  #main .img-listing .card.card_style_2 .card-body .post_author_info_cover {
    flex-direction: column !important;
  }

  #main .img-listing .card .card-body .post_info .post_cat a {
    font-size: 9px;
  }

  #main .img-listing .card .card-body .post_info .post_created_on {
    font-size: 9px;
  }

  #main .login .bod .input-group {
    width: 94%;
    margin-bottom: 11px;
    height: 9%;
  }

  #header .bottom-header ul .dropdown .dropdown-menu {
    position: absolute;
    min-width: 25rem;
  }

  #main .login .bod a {
    padding: 5px 37px;
    width: 86%;
    display: inline-block;
  }
  #main .login .bod .input-group input {
    color: #828282;
    padding: 10px 5px;
  }
  .latest_div {
    text-align: center;
    padding: 10px 0 0;
  }
  #main .login .bod .btn-default {
    width: 93%;
  }
  .face {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .pe-1 {
      padding-right: 0 !important;
      text-align: center !important;
      margin-bottom: 25px;
    }
  }

  #main .my_account_sec .para p {
    font-size: 10px;
  }

  #main .faq-2 .brd {
    align-self: center;
  }

  #main .faq-2 .d-flex h2 {
    font-size: 12px;
  }

  #main .faq-2 .para p {
    font-size: 8px;
  }

  #main .faq-2 .brd .heading {
    padding: 20px 0;
    h3 {
      padding-left: 0;
      font-size: 20px;
      text-align: center;
    }
  }

  #header .bottom-header ul li a {
    word-spacing: -5px;
  }

  #main .faq-2 .brd .bnt a {
    padding: 10px 53px;
  }

  #main .mid7 .card .card-body .Poruka p {
    font-size: 13px !important;
  }

  footer#footer .copyright ul li a {
    font-size: 9px;
  }

  #main .creators_sec .card .card-body .card-title {
    font-size: 22px;
  }

  #main .popular_sec .card .card-body .post_title a {
    font-size: 14px;
  }

  footer#footer .copyright p {
    font-size: 10px;
  }

  #main .mid3 .bol h4 {
    font-size: 17px;
  }

  footer#footer .widgets .footer_style .button-1 {
    margin-bottom: 10px;
  }

  footer#footer .widgets .footer_style .button-2 p span {
    font-size: 23px;
  }

  footer#footer .widgets .footer_style .button-2 p {
    font-size: 13px;
  }

  #header .bottom-header ul li a {
    padding: 8px 6px;
    font-size: 13px;
  }

  #main .sec3 .gb {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .col-lg-6 {
      padding-top: 20px;

      p {
        font-size: 14px;
      }
    }
  }

  #main .sec3 .fac {
    width: auto !important;
  }

  #main .sec3 .bg {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .col-lg-6 {
      padding-top: 20px;
    }
  }

  #main .sec3 .btn a {
    font-size: 17px;
    padding: 13px 112px;
  }

  #main .sec3 .min {
    width: 50% !important;
  }

  #main .sec3 .right {
    padding-right: 8px !important;
  }

  #main .sec3 .pt-5 p {
    font-size: 10px;
  }

  #main .trending_sec {
    padding: 14px 0;
  }

  #main .trending_sec h1 {
    margin-bottom: 18px;
  }

  #main .popular_sec {
    padding: 19px 0;
  }

  #main .popular_sec h1 {
    margin-bottom: 31px;
  }

  #main .categories_sec {
    padding: 37px 0;
    padding-bottom: 20px;
  }

  #main .creators_sec h1 {
    padding: 0 0 24px;
  }

  #main .creators_sec {
    padding: 31px 0;
    padding-bottom: 0;
  }

  #main .sec3 .pt-5 h1 {
    font-size: 27px;

    .sec3 {
      .bg {
        .col-lg-6 {
          padding-top: 20px;
        }
      }
    }
  }

  #main .teams .row .card .card-body .card-text {
    font-size: 14px;
  }

  #main .forget-password .bod {
    padding-bottom: 20px;
  }

  #main .select .backround {
    width: 110%;
  }

  #main .select .back {
    width: 62%;
  }

  #main .select .text {
    width: 55%;
  }
}
@media (max-width: 767.98px) {
  #main .upload-video .row h2,
  #main_2 .upload-video .row h2 {
    font-size: 18px;
    padding-left: 0;
  }
  #root #main .mid ul li a,
  #root #main_2 .mid ul li a,
  #root #main .mid ul li button,
  #root #main_2 .mid ul li button,
  #root #main .mid ul li .dropdown,
  #root #main_2 .mid ul li .dropdown {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  #main .word .card .round,
  #main_2 .word .card .round {
    flex-direction: column;
  }
  .post_info_cover ul li a {
    width: 25px;
    height: 25px;
    font-size: 18px;
  }
  #main .listing_sec h1,
  #main_2 .listing_sec h1 {
    font-size: 18px;
    line-height: 18px;
    img {
      width: 40px;
    }
  }
  #main .my_account_sec .change h3,
  #main_2 .my_account_sec .change h3 {
    font-size: 17px;
    margin-bottom: 10px;
  }
  #main .my_account_sec .change p,
  #main_2 .my_account_sec .change p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  #main .my_account_sec .change .row label,
  #main_2 .my_account_sec .change .row label {
    padding: 0;
  }
  #main .my_account_sec .earn,
  #main_2 .my_account_sec .earn {
    background-image: unset !important;
  }
  #main .my_account_sec .earn .row .d-flex,
  #main_2 .my_account_sec .earn .row .d-flex {
    margin: 0;
  }
  #main .my_account_sec .earn .row ul,
  #main_2 .my_account_sec .earn .row ul {
    padding: 10px 0 0 0;
  }
  .child {
    display: flex;
  }
  #main .login .bod .btn-default {
    width: 93%;
  }
  #main .creators_sec .card .card-body .card-title {
    font-size: 16px;
  }
  #header .text-end {
    .live {
      margin: 0 0 5px 0;
    }
  }
  #header {
    .top-header {
      .row {
        .col-lg-6 {
          display: flex;
          justify-content: center;
        }
      }

      ul {
        li {
          a {
            font-size: 12px;
          }
        }
      }

      .header-style a i {
        color: #fff !important;
      }
    }

    .mid-header {
      img {
        padding: 15px 0;
      }

      .align-self-center {
        .justify-content-end {
          justify-content: center !important;
        }
      }

      .bn {
        justify-content: center;
        display: flex;
      }

      .dropdown {
        text-align: center;
      }
    }
  }
  // .submit .text-end,
  // .text-start {
  //   text-align: center !important;
  // }
  .submit .text-start {
    margin-bottom: 20px;
  }
  .tab {
    margin-top: 11px;
  }

  #main .img-listing .card .card-body .post_info .post_cat a {
    font-size: 17px;
  }

  #main .img-listing .card.card_style_2 .card-body .post_info {
    text-align: center;
  }

  #main .img-listing .card.card_style_2 .card-body .post_title {
    text-align: center;
  }

  #main .img-listing .card .card-body .post_info .post_created_on {
    font-size: 17px;
  }

  #main .topContributorsSection .rost .d-flex {
    justify-content: center;
  }

  #main .select .backround {
    width: 100%;
  }

  #main .forget-password .bod {
    padding-bottom: 20px;
  }

  #main .edittext .exciting h3 {
    font-size: 18px;
  }

  #main .edittext .poem h3 {
    font-size: 20px;
  }

  #header .bottom-header ul li a {
    word-spacing: -4px;
  }

  #main .ad_sec {
    padding-bottom: 20px;
  }

  #main .trending_sec #trendingPostTabs .nav-link {
    padding: 0;
  }

  #main .my_account_sec .litf {
    margin-left: 0 !important;
    margin: 10px;
  }

  #main .faq-2 .brd h5 {
    text-align: center;
  }

  #main .faq-2 .brd {
    padding: 20px 15px 0;
  }

  #main .popular_sec .card .card-body .post_author_info_cover .post_stats {
    padding-left: 0;
  }

  #main .banner2 h2 {
    font-size: 20px;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_author_info
    .author_name
    a {
    font-size: 9px;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .views,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .comments,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .likes {
    font-size: 9px;
  }

  #main .trending_sec #trendingPostTabs li a {
    font-size: 14px;
  }

  #main .categories_sec {
    padding: 26px 0 14px 0;
  }

  footer#footer .widgets {
    padding: 27px 0 36px;
  }

  footer#footer .widgets ul {
    margin-bottom: 10px;
  }

  #main .creators_sec {
    padding-bottom: 0;
  }

  #main .creators_sec .card .card-body {
    text-align: center;
  }

  #main .categories_sec .item h4 a {
    font-size: 16px;
  }

  #main .sec3 .states a .w-37 {
    width: auto;
  }

  #main .sec3 .p-0 {
    text-align: start !important;

    .fac {
      width: auto !important;
    }
  }

  #header .bottom-header ul li a {
    padding: 7px 5px;
    font-size: 10px;
  }

  #header .bottom-header ul .dropdown .dropdown-menu {
    min-width: 20rem;
  }

  .asa {
    display: flex;
    justify-content: center;
  }

  #main .mid .ecs {
    display: flex;
    justify-content: center;
  }

  #main .sec3 .bg .col-lg-6 {
    width: auto;
  }

  footer#footer .copyright ul {
    text-align: center;

    display: flex;
    justify-content: center;
    a {
      font-size: 12px !important;
    }
  }

  footer#footer .copyright p {
    text-align: center;
    font-size: 12px;
  }

  #main .mid6 h3 {
    font-size: 13px;
  }

  .mid3 {
    h4 {
      font-size: 17px;
    }
  }
}
@media (max-width: 575.98px) {
  #main .login .bod .signin,
  #main_2 .login .bod .signin {
    padding: 15px;
  }
  .popular_sec .card .card-img img,
  .popular_sec .card .card-img {
    // width: 100% !important;
    border-radius: 0 !important;
  }
  .bottom-header2 {
    display: block !important;
  }

  .bottom-header {
    display: none;
  }
  section.userProfileSection #listing_main .listing_sec ul li a img {
    height: auto;
  }
}
@media (max-width: 500.98px) {
  .child {
    display: flex;
  }
  #header {
    .top-header {
      ul {
        li {
          a {
            font-size: 11px;
          }
        }
      }
    }

    .mid-header {
      .dropdown {
        display: flex;
        justify-content: center;
      }

      img {
        padding: 15px 0;
      }

      .align-self-center {
        .justify-content-end {
          justify-content: center !important;
        }
      }

      .bn {
        justify-content: center;
        display: flex;
      }
    }
    .text-end {
      .container {
        justify-content: end !important;
      }
      ul {
        li {
          a {
            margin: 10px 0;
          }
        }
      }
    }
  }
  #main .userProfileSection .pt-5 .nav-tabs li button img {
    padding-left: 0px;
    width: 103%;
  }
  .row_2 {
    .d-flex {
      flex-direction: column;
    }
  }
  #main .upload-video .dot h5 {
    font-size: 14px;
  }
  #main .publish p {
    font-family: " Editors-Note";
    font-weight: 400;
    font-size: 11px;
  }
  #main .popular_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .categories_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .creators_sec h1 {
    font-size: 20px;
    img {
      max-width: 42px !important;
    }
  }
  #main .select .text {
    padding: 0;
  }

  #main .forget-password .bod .forget {
    font-size: 33px;
  }

  #main .teams .btn-default {
    width: 51%;
  }

  #main .info_1 .back h1 {
    text-align: center;
  }

  #main .info_1 .back .row .col-lg-6 .imformation h5 {
    font-size: 18px;
  }

  #main .my_account_sec .withdraw .top_withdraw .available p {
    font-size: 13px;
  }

  #main .my_account_sec .withdraw h6 {
    font-size: 20px;
  }

  #main .my_account_sec .withdraw .top_withdraw .coin a {
    font-size: 17px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .input-group {
    width: 100%;
  }

  #main .my_account_sec .withdraw .mid_withdraw label {
    padding: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .d-flex p {
    padding-left: 55px;
    padding-right: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 {
    padding-left: 0px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 p {
    font-size: 12px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-6 {
    padding-left: 0px;
  }

  #main .popular_sec {
    padding-bottom: 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_author_info
    .author_name
    a {
    font-size: 15px;
  }

  #aboutus_main .banner .aboutus_heading {
    font-size: 33px;
  }

  #main .sec1 {
    padding: 35px 0;
  }

  #main .sec3 {
    padding: 37px 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .views,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .comments,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .likes {
    font-size: 11px;
  }

  #main {
    //home
    .trending_sec {
      padding: 10px 0;

      h1 {
        font-size: 25px;
        margin-bottom: 15px;

        img {
          height: 32px;
        }
      }

      #trendingPostTabs {
        li {
          a {
            font-size: 15px;
          }
        }
      }

      .tab-content {
        .tab-pane {
          .popular_sec {
            .card {
              .card-body {
                .post_info {
                  .post_cat {
                    a {
                      font-size: 11px;
                    }
                  }

                  .post_created_on {
                    font-size: 11px;
                  }
                }

                .post_title {
                  font-size: 16px;
                }

                .author_name {
                  a {
                    font-size: 10px;
                  }
                }

                .post_stats {
                  .views,
                  .comments,
                  .likes {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .creators_sec {
      // img {
      //   width: auto;
      // }

      .center {
        display: flex;
        justify-content: center;
      }

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    //advertisement
    .banner2 h2 {
      font-size: 14px;
    }

    .banner2 {
      padding: 30px 0;
    }

    .banner2 h1 {
      font-size: 27px;
    }

    .info_1 {
      padding: 30px 0;

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #main .ad_sec {
    padding: 25px 0;
  }

  #aboutus_main .banner {
    padding: 30px 0 0;
  }

  #main .mid4 .d-flex {
    justify-content: center;

    .tint {
      a {
        font-size: 10px;
        padding: 9px 12px;
      }
    }
  }

  #main .blog .date p {
    font-size: 12px;
  }

  #main .blog h2 {
    font-size: 19px !important;
  }

  #main .mid .ecs ul {
    justify-content: center !important;
  }

  #main .mid .ecs {
    justify-content: center;
  }

  #main .mid6 .gap {
    padding-left: 12px;
    padding-top: 12px;
  }

  #main .mid6 .bl {
    padding-bottom: 10px;
  }

  #main .popular_sec .card .card-body .post_info .post_created_on {
    font-size: 14px;
  }

  #main .popular_sec .card .card-body .post_author_info_cover .post_stats {
    padding-top: 10px;
  }

  #main .popular_sec .card.card_style_2 .card-img-top {
    max-width: 33%;
  }

  footer#footer .widgets {
    padding: 29px 0 36px;
  }

  #main .categories_sec {
    padding: 30px 0 20px 0;
  }

  #main .creators_sec {
    padding-top: 30px;
  }

  #main .popular_sec .card.card_style_2 .card-body .post_author_info_cover {
    flex-direction: column;
  }

  #main
    .trending_sec
    .tab-content
    .tab-pane
    .popular_sec
    .card
    .card-body
    .author_name
    a {
    font-size: 15px;
  }

  .saa {
    padding: 0 !important;
  }
  #main .faq-2 .para p {
    font-size: 9px;
    margin: 5px;
  }
  #main .topContributorsSection .btn-default {
    background-color: #e5097d;
    border: 0;
    padding: 5px 14px;
    font-size: 12px;
  }
  #main .topContributorsSection h6 {
    font-size: 13px;
  }
  #main .topContributorsSection p {
    font-size: 11px;
    color: #e5097d;
  }
  #main .topContributorsSection img {
    width: 80%;
  }
  #Terms_conditions_main .Terms_conditions_sec {
    padding: 33px 0;
  }

  #footer {
    .widgets {
      h1 {
        padding: 0px 0 0;
      }

      .footer_style {
        .button-1,
        .button-2 {
          margin: 10px 0;
        }

        .cp {
          p {
            span {
              font-size: 24px !important;
            }
          }
        }
      }
    }

    .copyright {
      p {
        text-align: center !important;
        padding: 10px 0 0;
      }
      ul {
        li {
          a {
            font-size: 10px !important;
          }
        }
      }
    }
  }
  #main .blog {
    padding: 21px 0;
    padding-bottom: 0 !important;
  }
  .face .pe-1 {
    padding-right: 0 !important;
    text-align: center !important;
    margin-bottom: 30px;
  }
}
@media (max-width: 525.98px) {
  #footer .copyright ul li a {
    font-size: 8px !important;
  }
  #header .bottom-header2 ul li a {
    font-size: 16px !important;
    padding: 5px 10px;
  }
  .bottom-header2 {
    display: block !important;
  }
  .login {
    .pb-5 {
      padding-bottom: 0rem !important;
    }
    .p-5 {
      padding: 0 !important;
    }
  }
  .bottom-header {
    display: none;
  }
}
@media (max-width: 505.98px) {
  .child {
    display: flex;
  }
  #header {
    .top-header {
      ul {
        li {
          a {
            font-size: 11px;
          }
        }
      }
    }

    .mid-header {
      .dropdown {
        display: flex;
        justify-content: center;
      }

      img {
        padding: 15px 0;
      }

      .align-self-center {
        .justify-content-end {
          justify-content: center !important;
        }
      }

      .bn {
        justify-content: center;
        display: flex;
      }
    }
    .text-end {
      .container {
        justify-content: end !important;
      }
      ul {
        li {
          a {
            margin: 10px 0;
          }
        }
      }
    }
  }
  #main .userProfileSection .pt-5 .nav-tabs li button img {
    padding-left: 0px;
    width: 103%;
  }
  .row_2 {
    .d-flex {
      flex-direction: column;
    }
  }
  #main .upload-video .dot h5 {
    font-size: 14px;
  }
  #main .publish p {
    font-family: " Editors-Note";
    font-weight: 400;
    font-size: 11px;
  }
  #main .popular_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .categories_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .creators_sec h1 {
    font-size: 20px;
    img {
      max-width: 42px !important;
    }
  }
  #main .select .text {
    padding: 0;
  }

  #main .forget-password .bod .forget {
    font-size: 33px;
  }

  #main .teams .btn-default {
    width: 51%;
  }

  .bottom-header2 {
    display: block !important;
  }

  .bottom-header {
    display: none;
  }

  #main .info_1 .back h1 {
    text-align: center;
  }

  #main .info_1 .back .row .col-lg-6 .imformation h5 {
    font-size: 18px;
  }

  #main .my_account_sec .withdraw .top_withdraw .available p {
    font-size: 13px;
  }

  #main .my_account_sec .withdraw h6 {
    font-size: 20px;
  }

  #main .my_account_sec .withdraw .top_withdraw .coin a {
    font-size: 17px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .input-group {
    width: 100%;
  }

  #main .my_account_sec .withdraw .mid_withdraw label {
    padding: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .d-flex p {
    padding-left: 55px;
    padding-right: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 {
    padding-left: 0px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 p {
    font-size: 12px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-6 {
    padding-left: 0px;
  }

  #main .popular_sec {
    padding-bottom: 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_author_info
    .author_name
    a {
    font-size: 15px;
  }

  #aboutus_main .banner .aboutus_heading {
    font-size: 33px;
  }

  #main .sec1 {
    padding: 35px 0;
  }

  #main .sec3 {
    padding: 37px 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .views,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .comments,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .likes {
    font-size: 11px;
  }

  #main {
    //home
    .trending_sec {
      padding: 10px 0;

      h1 {
        font-size: 25px;
        margin-bottom: 15px;

        img {
          height: 32px;
        }
      }

      #trendingPostTabs {
        li {
          a {
            font-size: 15px;
          }
        }
      }

      .tab-content {
        .tab-pane {
          .popular_sec {
            .card {
              .card-body {
                .post_info {
                  .post_cat {
                    a {
                      font-size: 11px;
                    }
                  }

                  .post_created_on {
                    font-size: 11px;
                  }
                }

                .post_title {
                  font-size: 16px;
                }

                .author_name {
                  a {
                    font-size: 10px;
                  }
                }

                .post_stats {
                  .views,
                  .comments,
                  .likes {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .creators_sec {
      // img {
      //   width: auto;
      // }

      .center {
        display: flex;
        justify-content: center;
      }

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    //advertisement
    .banner2 h2 {
      font-size: 14px;
    }

    .banner2 {
      padding: 30px 0;
    }

    .banner2 h1 {
      font-size: 27px;
    }

    .info_1 {
      padding: 30px 0;

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #main .ad_sec {
    padding: 25px 0;
  }

  #aboutus_main .banner {
    padding: 30px 0 0;
  }

  #main .mid4 .d-flex {
    justify-content: center;

    .tint {
      a {
        font-size: 10px;
        padding: 9px 12px;
      }
    }
  }

  #main .blog .date p {
    font-size: 12px;
  }

  #main .blog h2 {
    font-size: 19px !important;
  }

  #main .mid .ecs ul {
    justify-content: center !important;
  }

  #main .mid .ecs {
    justify-content: center;
  }

  #main .mid6 .gap {
    padding-left: 12px;
    padding-top: 12px;
  }

  #main .mid6 .bl {
    padding-bottom: 10px;
  }

  #main .popular_sec .card .card-body .post_info .post_created_on {
    font-size: 14px;
  }

  #main .popular_sec .card .card-body .post_author_info_cover .post_stats {
    padding-top: 10px;
  }

  #main .popular_sec .card.card_style_2 .card-img-top {
    max-width: 33%;
  }

  footer#footer .widgets {
    padding: 29px 0 36px;
  }

  #main .categories_sec {
    padding: 30px 0 20px 0;
  }

  #main .creators_sec {
    padding-top: 30px;
  }

  #main .popular_sec .card.card_style_2 .card-body .post_author_info_cover {
    flex-direction: column;
  }

  #main
    .trending_sec
    .tab-content
    .tab-pane
    .popular_sec
    .card
    .card-body
    .author_name
    a {
    font-size: 15px;
  }

  .saa {
    padding: 0 !important;
  }
  #main .faq-2 .para p {
    font-size: 9px;
    margin: 5px;
  }
  #main .topContributorsSection .btn-default {
    background-color: #e5097d;
    border: 0;
    padding: 5px 14px;
    font-size: 12px;
  }
  #main .topContributorsSection h6 {
    font-size: 13px;
  }
  #main .topContributorsSection p {
    font-size: 11px;
    color: #e5097d;
  }
  #main .topContributorsSection img {
    width: 80%;
  }
  #Terms_conditions_main .Terms_conditions_sec {
    padding: 33px 0;
  }

  #footer {
    .widgets {
      h1 {
        padding: 0px 0 0;
      }

      .footer_style {
        .button-1,
        .button-2 {
          margin: 10px 0;
        }

        .cp {
          p {
            span {
              font-size: 24px !important;
            }
          }
        }
      }
    }

    .copyright {
      p {
        text-align: center !important;
        padding: 10px 0 0;
      }
      ul {
        li {
          a {
            font-size: 10px !important;
          }
        }
      }
    }
  }
  #main .blog {
    padding: 21px 0;
    padding-bottom: 0 !important;
  }
  .face .pe-1 {
    padding-right: 0 !important;
    text-align: center !important;
    margin-bottom: 30px;
  }
}
@media (max-width: 428.98px) {
  .child {
    display: flex;
  }
  #header {
    .top-header {
      ul {
        li {
          a {
            font-size: 11px;
          }
        }
      }
    }

    .mid-header {
      .dropdown {
        display: flex;
        justify-content: center;
      }

      img {
        padding: 15px 0;
      }

      .align-self-center {
        .justify-content-end {
          justify-content: center !important;
        }
      }

      .bn {
        justify-content: center;
        display: flex;
      }
    }
    .text-end {
      ul {
        li {
          a {
            margin: 10px 0;
          }
        }
      }
    }
  }
  #footer .copyright ul li a {
    font-size: 9px !important;
  }
  #main .userProfileSection .pt-5 .nav-tabs li button img {
    padding-left: 0px;
    width: 103%;
  }
  .row_2 {
    .d-flex {
      flex-direction: column;
    }
  }
  #main .upload-video .dot h5 {
    font-size: 14px;
  }
  #main .publish p {
    font-family: " Editors-Note";
    font-weight: 400;
    font-size: 11px;
  }
  #main .popular_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .categories_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .creators_sec h1 {
    font-size: 20px;
    img {
      max-width: 42px !important;
    }
  }
  #main .select .text {
    padding: 0;
  }

  #main .forget-password .bod .forget {
    font-size: 33px;
  }

  #main .teams .btn-default {
    width: 51%;
  }

  .bottom-header {
    display: none !important;
  }

  .bottom-header2 {
    display: block;
  }

  #main .info_1 .back h1 {
    text-align: center;
  }

  #main .info_1 .back .row .col-lg-6 .imformation h5 {
    font-size: 18px;
  }

  #main .my_account_sec .withdraw .top_withdraw .available p {
    font-size: 13px;
  }

  #main .my_account_sec .withdraw h6 {
    font-size: 20px;
  }

  #main .my_account_sec .withdraw .top_withdraw .coin a {
    font-size: 17px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .input-group {
    width: 100%;
  }

  #main .my_account_sec .withdraw .mid_withdraw label {
    padding: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .d-flex p {
    padding-left: 55px;
    padding-right: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 {
    padding-left: 0px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 p {
    font-size: 12px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-6 {
    padding-left: 0px;
  }

  #main .popular_sec {
    padding-bottom: 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_author_info
    .author_name
    a {
    font-size: 15px;
  }

  #aboutus_main .banner .aboutus_heading {
    font-size: 33px;
  }

  #main .sec1 {
    padding: 35px 0;
  }

  #main .sec3 {
    padding: 37px 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .views,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .comments,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .likes {
    font-size: 11px;
  }

  #main {
    //home
    .trending_sec {
      padding: 10px 0;

      h1 {
        font-size: 25px;
        margin-bottom: 15px;

        img {
          height: 32px;
        }
      }

      #trendingPostTabs {
        li {
          a {
            font-size: 15px;
          }
        }
      }

      .tab-content {
        .tab-pane {
          .popular_sec {
            .card {
              .card-body {
                .post_info {
                  .post_cat {
                    a {
                      font-size: 11px;
                    }
                  }

                  .post_created_on {
                    font-size: 11px;
                  }
                }

                .post_title {
                  font-size: 16px;
                }

                .author_name {
                  a {
                    font-size: 10px;
                  }
                }

                .post_stats {
                  .views,
                  .comments,
                  .likes {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .creators_sec {
      // img {
      //   width: auto;
      // }

      .center {
        display: flex;
        justify-content: center;
      }

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    //advertisement
    .banner2 h2 {
      font-size: 14px;
    }

    .banner2 {
      padding: 30px 0;
    }

    .banner2 h1 {
      font-size: 27px;
    }

    .info_1 {
      padding: 30px 0;

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #main .ad_sec {
    padding: 25px 0;
  }

  #aboutus_main .banner {
    padding: 30px 0 0;
  }

  #main .mid4 .d-flex {
    justify-content: center;

    .tint {
      a {
        font-size: 10px;
        padding: 9px 12px;
      }
    }
  }

  #main .blog .date p {
    font-size: 12px;
  }

  #main .blog h2 {
    font-size: 19px !important;
  }

  #main .mid .ecs ul {
    justify-content: center !important;
  }

  #main .mid .ecs {
    justify-content: center;
  }

  #main .mid6 .gap {
    padding-left: 12px;
    padding-top: 12px;
  }

  #main .mid6 .bl {
    padding-bottom: 10px;
  }

  #main .popular_sec .card .card-body .post_info .post_created_on {
    font-size: 14px;
  }

  #main .popular_sec .card .card-body .post_author_info_cover .post_stats {
    padding-top: 10px;
  }

  #main .popular_sec .card.card_style_2 .card-img-top {
    max-width: 33%;
  }

  footer#footer .widgets {
    padding: 29px 0 36px;
  }

  #main .categories_sec {
    padding: 30px 0 20px 0;
  }

  #main .creators_sec {
    padding-top: 30px;
  }

  #main .popular_sec .card.card_style_2 .card-body .post_author_info_cover {
    flex-direction: column;
  }

  #main
    .trending_sec
    .tab-content
    .tab-pane
    .popular_sec
    .card
    .card-body
    .author_name
    a {
    font-size: 15px;
  }

  .saa {
    padding: 0 !important;
  }
  #main .faq-2 .para p {
    font-size: 9px;
    margin: 5px;
  }
  #main .topContributorsSection .btn-default {
    background-color: #e5097d;
    border: 0;
    padding: 5px 14px;
    font-size: 12px;
  }
  #main .topContributorsSection h6 {
    font-size: 13px;
  }
  #main .topContributorsSection p {
    font-size: 11px;
    color: #e5097d;
  }
  #main .topContributorsSection img {
    width: 80%;
  }
  #Terms_conditions_main .Terms_conditions_sec {
    padding: 33px 0;
  }

  #footer {
    .widgets {
      h1 {
        padding: 0px 0 0;
      }

      .footer_style {
        .button-1,
        .button-2 {
          margin: 10px 0;
        }

        .cp {
          p {
            span {
              font-size: 24px !important;
            }
          }
        }
      }
    }

    .copyright {
      p {
        text-align: center !important;
        padding: 10px 0 0;
      }
    }
  }
  #main .blog {
    padding: 21px 0;
    padding-bottom: 0 !important;
  }
  .face .pe-1 {
    padding-right: 0 !important;
    text-align: center !important;
    margin-bottom: 30px;
  }
  #main .listing_sec {
    .left-text {
      text-align: center;
    }
    .col-lg-6 {
      display: flex;
      justify-content: center !important;
      margin-bottom: 20px;
      .d-flex {
        justify-content: center !important;
      }
    }
  }
  .top_bloggers {
    ul {
      overflow-x: scroll;
      overflow-y: hidden;
      flex-direction: initial;
      flex-wrap: nowrap;
      li {
        a {
          white-space: nowrap;
        }
      }
    }
  }
}
@media (max-width: 425.98px) {
  .child {
    display: flex;
  }
  .select {
    .row {
      justify-content: center !important;
      text-align: center !important;
      .end {
        text-align: center !important;
        margin-top: 10px;
      }
    }
  }
  footer#footer .copyright ul {
    text-align: center;

    display: flex;
    justify-content: center;
    a {
      font-size: 9px !important;
    }
  }
  #header {
    .top-header {
      ul {
        li {
          a {
            font-size: 11px;
          }
        }
      }
    }

    .mid-header {
      .dropdown {
        display: flex;
        justify-content: center;
      }

      img {
        padding: 15px 0;
      }

      .align-self-center {
        .justify-content-end {
          justify-content: center !important;
        }
      }

      .bn {
        justify-content: center;
        display: flex;
      }
    }
    .text-end {
      .container {
        justify-content: end !important;
      }
      ul {
        li {
          a {
            margin: 10px 0;
          }
        }
      }
    }
  }
  #main .userProfileSection .pt-5 .nav-tabs li button img {
    padding-left: 0px;
    width: 103%;
  }
  .row_2 {
    .d-flex {
      flex-direction: column;
    }
  }
  #main .upload-video .dot h5 {
    font-size: 14px;
  }
  #main .publish p {
    font-family: " Editors-Note";
    font-weight: 400;
    font-size: 11px;
  }
  #main .popular_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .categories_sec h1 {
    font-size: 20px;
    img {
      max-width: 45px;
    }
  }
  #main .creators_sec h1 {
    font-size: 20px;
    img {
      max-width: 42px !important;
    }
  }
  #main .select .text {
    padding: 0;
  }

  #main .forget-password .bod .forget {
    font-size: 33px;
  }

  #main .teams .btn-default {
    width: 51%;
  }

  #main .info_1 .back h1 {
    text-align: center;
  }

  #main .info_1 .back .row .col-lg-6 .imformation h5 {
    font-size: 18px;
  }

  #main .my_account_sec .withdraw .top_withdraw .available p {
    font-size: 13px;
  }

  #main .my_account_sec .withdraw h6 {
    font-size: 20px;
  }

  #main .my_account_sec .withdraw .top_withdraw .coin a {
    font-size: 17px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .input-group {
    width: 100%;
  }

  #main .my_account_sec .withdraw .mid_withdraw label {
    padding: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .d-flex p {
    padding-left: 55px;
    padding-right: 0;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 {
    padding-left: 0px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-7 p {
    font-size: 12px;
  }

  #main .my_account_sec .withdraw .mid_withdraw .ps-6 {
    padding-left: 0px;
  }

  #main .popular_sec {
    padding-bottom: 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_author_info
    .author_name
    a {
    font-size: 15px;
  }

  #aboutus_main .banner .aboutus_heading {
    font-size: 33px;
  }

  #main .sec1 {
    padding: 35px 0;
  }

  #main .sec3 {
    padding: 37px 0;
  }

  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .views,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .comments,
  #main
    .popular_sec
    .card
    .card-body
    .post_author_info_cover
    .post_stats
    .likes {
    font-size: 11px;
  }

  #main {
    //home
    .trending_sec {
      padding: 10px 0;

      h1 {
        font-size: 25px;
        margin-bottom: 15px;

        img {
          height: 32px;
        }
      }

      #trendingPostTabs {
        li {
          a {
            font-size: 15px;
          }
        }
      }

      .tab-content {
        .tab-pane {
          .popular_sec {
            .card {
              .card-body {
                .post_info {
                  .post_cat {
                    a {
                      font-size: 11px;
                    }
                  }

                  .post_created_on {
                    font-size: 11px;
                  }
                }

                .post_title {
                  font-size: 16px;
                }

                .author_name {
                  a {
                    font-size: 10px;
                  }
                }

                .post_stats {
                  .views,
                  .comments,
                  .likes {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .creators_sec {
      // img {
      //   width: auto;
      // }

      .center {
        display: flex;
        justify-content: center;
      }

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    //advertisement
    .banner2 h2 {
      font-size: 14px;
    }

    .banner2 {
      padding: 30px 0;
    }

    .banner2 h1 {
      font-size: 27px;
    }

    .info_1 {
      padding: 30px 0;

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #main .ad_sec {
    padding: 25px 0;
  }

  #aboutus_main .banner {
    padding: 30px 0 0;
  }

  #main .mid4 .d-flex {
    justify-content: center;

    .tint {
      a {
        font-size: 10px;
        padding: 9px 12px;
      }
    }
  }

  #main .blog .date p {
    font-size: 12px;
  }

  #main .blog h2 {
    font-size: 19px !important;
  }

  #main .mid .ecs ul {
    justify-content: center !important;
  }

  #main .mid .ecs {
    justify-content: center;
  }

  #main .mid6 .gap {
    padding-left: 12px;
    padding-top: 12px;
  }

  #main .mid6 .bl {
    padding-bottom: 10px;
  }

  #main .popular_sec .card .card-body .post_info .post_created_on {
    font-size: 14px;
  }

  #main .popular_sec .card .card-body .post_author_info_cover .post_stats {
    padding-top: 10px;
  }

  #main .popular_sec .card.card_style_2 .card-img-top {
    max-width: 33%;
  }

  footer#footer .widgets {
    padding: 29px 0 36px;
  }

  #main .categories_sec {
    padding: 30px 0 20px 0;
  }

  #main .creators_sec {
    padding-top: 30px;
  }

  #main .popular_sec .card.card_style_2 .card-body .post_author_info_cover {
    flex-direction: column;
  }

  #main
    .trending_sec
    .tab-content
    .tab-pane
    .popular_sec
    .card
    .card-body
    .author_name
    a {
    font-size: 15px;
  }

  .saa {
    padding: 0 !important;
  }
  #main .faq-2 .para p {
    font-size: 9px;
    margin: 5px;
  }
  #main .topContributorsSection .btn-default {
    background-color: #e5097d;
    border: 0;
    padding: 5px 14px;
    font-size: 12px;
  }
  #main .topContributorsSection h6 {
    font-size: 13px;
  }
  #main .topContributorsSection p {
    font-size: 11px;
    color: #e5097d;
  }
  #main .topContributorsSection img {
    width: 80%;
  }
  #Terms_conditions_main .Terms_conditions_sec {
    padding: 33px 0;
  }

  #footer {
    .widgets {
      h1 {
        padding: 0px 0 0;
      }

      .footer_style {
        .button-1,
        .button-2 {
          margin: 10px 0;
        }

        .cp {
          p {
            span {
              font-size: 24px !important;
            }
          }
        }
      }
    }

    .copyright {
      p {
        text-align: center !important;
        padding: 10px 0 0;
      }
    }
  }
  #main .blog {
    padding: 21px 0;
    padding-bottom: 0 !important;
  }
  .face .pe-1 {
    padding-right: 0 !important;
    text-align: center !important;
    margin-bottom: 30px;
  }
}
@media (max-width: 420.98px) {
  #footer .copyright ul li a {
    font-size: 8px !important;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  // }
}
@media (max-width: 400.98px) {
  #footer .copyright ul li a {
    font-size: 8px !important;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  // }
}
@media (max-width: 414.98px) {
  #footer .copyright ul li a {
    font-size: 8px !important;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  // }
}
@media (max-width: 412.98px) {
  #footer .copyright ul li a {
    font-size: 8px !important;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  // }
}
@media (max-width: 398.98px) {
  #main .faq-2 .para p {
    font-size: 7px;
  }
  #footer .copyright ul li a {
    font-size: 8px !important;
    padding: 7px;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  //   padding: 8px;
  // }
}
@media (max-width: 390.98px) {
  #footer .copyright ul li a {
    font-size: 7px !important;
    padding: 4px;
  }
  #header .top-header ul li a {
    font-size: 9px;
  }
}
@media (max-width: 389.98px) {
  #main .faq-2 .para p {
    font-size: 7px;
  }
  #footer .copyright ul li a {
    font-size: 10px !important;
    padding: 5px;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  //   padding: 8px;
  // }
}
@media (max-width: 387.98px) {
  #main .faq-2 .para p {
    font-size: 7px;
  }
  #footer .copyright ul li a {
    font-size: 10px !important;
    padding: 5px;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  //   padding: 9px;
  // }
}
@media (max-width: 385.98px) {
  #main .faq-2 .para p {
    font-size: 7px;
  }
  #footer .copyright ul li a {
    font-size: 10px !important;
    padding: 5px;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  //   padding: 8px;
  // }
}
@media (max-width: 375.98px) {
  #main .faq-2 .para p {
    font-size: 7px;
  }
  #footer .copyright ul li a {
    font-size: 9px !important;
    padding: 4px;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px;
  //   padding: 7px;
  // }
}
@media (max-width: 365.98px) {
  #main .faq-2 .para p {
    font-size: 7px;
  }
  #footer .copyright ul li a {
    font-size: 8px !important;
    padding: 5px;
  }
  #header .bottom-header2 .container {
    padding: 0;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px !important;
  //   padding: 7px;
  // }
}
@media (max-width: 320.98px) {
  #main .faq-2 .para p {
    font-size: 7px;
  }
  #main .login .bod .google {
    background-color: #fff;
    /* padding: 16px 20px; */
    font-size: 13px;
  }

  #header .top-header ul li a {
    padding: 8px 5px;
    font-size: 10px;
  }
  #footer .copyright ul li a {
    font-size: 7px !important;
    padding: 3px;
  }
  // #header .bottom-header2 ul li a {
  //   font-size: 8px;
  //   padding: 5px;
  // }
}
@media (max-width: 300.98px) {
  #main .login .bod a {
    font-size: 12px;
  }
  #main .login .bod .google {
    font-size: 12px;
  }
}
@media (max-width: 270.98px) {
  #header .top-header ul li a {
    font-size: 9px;
  }
  #main .login .bod a {
    font-size: 10px;
  }
  #footer .copyright ul li a {
    font-size: 6px !important;
    padding: 3px;
  }
}

/////////////////////oTp/////////////////////////////
// $color_1: #ffffff;
// $color_2: #1d1d1b;
// $color_3: #616161;
// $color_4: #e5097d;
// $color_5: transparent;
// $color_6: #55acee;
// $color_7: #818181;
// $font-family_1: "Overpass", Helvetica;
// $font-family_2: "Inter", Helvetica;
// $font-family_3: "Poppins", Helvetica;
// $font-family_4: "Roboto", Helvetica;
// $background-color_1: #ffffff;
// $background-color_2: #e5097d;
// $background-color_3: #48277f;
// $background-color_4: #e32975;
// $border-color_1: #00000036;
// $border-color_2: #d9d9d9;
// $border-color_3: #a7a7a7;

// .enter-OTP-screen {
//   background-color: $background-color_1;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   width: 100%;

//   .div {
//     background-color: $background-color_1;
//     height: 800px;
//     overflow: hidden;
//     position: relative;
//     width: 1440px;
//   }

//   .overlap {
//     background-color: $background-color_2;
//     height: 65px;
//     left: 1px;
//     position: absolute;
//     top: 219px;
//     width: 1441px;
//   }

//   .otp-input {
//     /* width: 72px; */
//     /* height: 67px; */
//     justify-content: space-between;
//     width: 100%;
//     margin-bottom: 20px;

//     input[type="tel"] {
//       width: 72px !important;
//       height: 67px !important;
//       font-size: 18px !important;
//       border-radius: 5px;
//       border: 1px solid #A7A7A7;
//       background: #FFF;
//     }
//   }

//   .creators-baby-kids {
//     color: $color_1;
//     font-family: $font-family_1;
//     font-size: 14px;
//     font-weight: 700;
//     height: 33px;
//     left: 369px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 15px;
//     width: 710px;
//   }

//   .overlap-group {
//     background-color: $background-color_3;
//     height: 52px;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 1441px;
//   }

//   .HOME-ABOUT-LATEST {
//     color: $color_1;
//     font-family: $font-family_1;
//     font-size: 16px;
//     font-weight: 700;
//     left: 121px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 19px;
//     width: 381px;
//   }

//   .overlap-2 {
//     height: 24px;
//     left: 1112px;
//     position: absolute;
//     top: 17px;
//     width: 209px;
//   }

//   .element {
//     color: $color_1;
//     font-family: $font-family_1;
//     font-size: 16px;
//     font-weight: 500;
//     left: 0;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 1px;
//   }

//   .history {
//     height: 24px;
//     left: 126px;
//     position: absolute;
//     top: 0;
//     width: 24px;
//   }

//   .calendar-month {
//     height: 24px;
//     left: 1080px;
//     position: absolute;
//     top: 17px;
//     width: 24px;
//   }

//   .isolation-mode {
//     height: 26px;
//     left: 991px;
//     position: absolute;
//     top: 123px;
//     width: 25px;
//   }

//   .overlap-3 {
//     background-color: $background-color_3;
//     border-radius: 3px;
//     height: 49px;
//     left: 1169px;
//     position: absolute;
//     top: 111px;
//     width: 152px;
//   }

//   .create {
//     color: $color_1;
//     font-family: $font-family_1;
//     font-size: 18px;
//     font-weight: 400;
//     height: 25px;
//     left: 59px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 11px;
//     width: 83px;
//   }

//   .img {
//     height: 25px;
//     left: 21px;
//     position: absolute;
//     top: 12px;
//     width: 25px;
//   }

//   .sign-IN {
//     color: $color_2;
//     font-family: $font-family_1;
//     font-size: 18px;
//     font-weight: 400;
//     height: 25px;
//     left: 1072px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 122px;
//     width: 83px;
//   }

//   .group {
//     height: 25px;
//     left: 1042px;
//     position: absolute;
//     top: 123px;
//     width: 24px;
//   }

//   .overlap-group-2 {
//     background-color: $background-color_3;
//     border-radius: 58px;
//     height: 56px;
//     left: 121px;
//     position: absolute;
//     top: 108px;
//     width: 166px;
//   }

//   .text-wrapper {
//     color: $color_1;
//     font-family: $font-family_1;
//     font-size: 14px;
//     font-weight: 500;
//     height: 46px;
//     left: 21px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 4px;
//     width: 91px;
//   }

//   .vector {
//     height: 8px;
//     left: 130px;
//     position: absolute;
//     top: 32px;
//     width: 12px;
//   }

//   .element-2 {
//     height: 103px;
//     left: 576px;
//     object-fit: cover;
//     position: absolute;
//     top: 84px;
//     width: 272px;
//   }

//   .overlap-4 {
//     border: 1px solid;
//     border-color: $border-color_1;
//     height: 577px;
//     left: 119px;
//     position: absolute;
//     top: 106px;
//     width: 1202px;
//   }

//   .verify-OTP-wrapper {
//     background-color: $background-color_2;
//     border: 1px solid;
//     border-color: $border-color_2;
//     border-radius: 11px;
//     height: 65px;
//     left: 10px;
//     position: absolute;
//     top: 146px;
//     width: 478px;
//   }

//   .verify-OTP {
//     color: $color_1;
//     font-family: $font-family_2;
//     font-size: 20px;
//     font-weight: 600;
//     left: 179px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 19px;
//     white-space: nowrap;
//   }

//   .p {
//     color: $color_3;
//     font-family: $font-family_2;
//     font-size: 20px;
//     font-weight: 500;
//     left: 602px;
//     letter-spacing: 0;
//     line-height: 27.1px;
//     position: absolute;
//     text-align: center;
//     top: 153px;
//     width: 510px;
//   }

//   .enter-OTP-number {
//     color: $color_4;
//     font-family: $font-family_1;
//     font-size: 55px;
//     font-weight: 600;
//     left: 565px;
//     letter-spacing: -3.02px;
//     line-height: 64.6px;
//     position: absolute;
//     text-align: center;
//     top: 80px;
//     width: 583px;
//   }

//   .image {
//     height: 393px;
//     left: 84px;
//     object-fit: cover;
//     position: absolute;
//     top: 81px;
//     width: 373px;
//   }

//   .overlap-5 {
//     height: 74px;
//     left: 619px;
//     position: absolute;
//     top: 211px;
//     width: 478px;
//   }

//   .group-2 {
//     height: 68px;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 478px;
//   }

//   .rectangle {
//     background-color: $background-color_1;
//     border: 1px solid;
//     border-color: $border-color_3;
//     border-radius: 5px;
//     height: 68px;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 72px;
//   }

//   .rectangle-2 {
//     background-color: $background-color_1;
//     border: 1px solid;
//     border-color: $border-color_3;
//     border-radius: 5px;
//     height: 68px;
//     left: 81px;
//     position: absolute;
//     top: 0;
//     width: 72px;
//   }

//   .rectangle-3 {
//     background-color: $background-color_1;
//     border: 1px solid;
//     border-color: $border-color_3;
//     border-radius: 5px;
//     height: 68px;
//     left: 162px;
//     position: absolute;
//     top: 0;
//     width: 72px;
//   }

//   .rectangle-4 {
//     background-color: $background-color_1;
//     border: 1px solid;
//     border-color: $border-color_3;
//     border-radius: 5px;
//     height: 68px;
//     left: 243px;
//     position: absolute;
//     top: 0;
//     width: 72px;
//   }

//   .rectangle-5 {
//     background-color: $background-color_1;
//     border: 1px solid;
//     border-color: $border-color_3;
//     border-radius: 5px;
//     height: 68px;
//     left: 324px;
//     position: absolute;
//     top: 0;
//     width: 72px;
//   }

//   .rectangle-6 {
//     background-color: $background-color_1;
//     border: 1px solid;
//     border-color: $border-color_3;
//     border-radius: 5px;
//     height: 68px;
//     left: 406px;
//     position: absolute;
//     top: 0;
//     width: 72px;
//   }

//   .text-wrapper-2 {
//     color: $color_3;
//     font-family: $font-family_3;
//     font-size: 25px;
//     font-weight: 400;
//     left: 23px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 14px;
//     width: 20px;
//   }

//   .text-wrapper-3 {
//     color: $color_3;
//     font-family: $font-family_3;
//     font-size: 25px;
//     font-weight: 400;
//     left: 435px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 18px;
//     width: 17px;
//   }

//   .text-wrapper-4 {
//     color: $color_3;
//     font-family: $font-family_3;
//     font-size: 25px;
//     font-weight: 400;
//     left: 105px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 14px;
//     width: 20px;
//   }

//   .text-wrapper-5 {
//     color: $color_3;
//     font-family: $font-family_3;
//     font-size: 25px;
//     font-weight: 400;
//     left: 189px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 16px;
//     width: 19px;
//   }

//   .text-wrapper-6 {
//     color: $color_3;
//     font-family: $font-family_3;
//     font-size: 25px;
//     font-weight: 400;
//     left: 273px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 16px;
//     width: 18px;
//   }

//   .text-wrapper-7 {
//     color: $color_3;
//     font-family: $font-family_3;
//     font-size: 25px;
//     font-weight: 400;
//     left: 353px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 14px;
//     width: 17px;
//   }

//   .element-resend {
//     color: $color_5;
//     font-family: $font-family_4;
//     font-size: 20px;
//     font-weight: 700;
//     left: 792px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 338px;
//     width: 129px;
//   }

//   .span {
//     color: $color_4;
//   }

//   .text-wrapper-8 {
//     color: $color_6;
//   }

//   .text-wrapper-9 {
//     color: $color_3;
//   }

//   .text-wrapper-10 {
//     color: $color_3;
//     font-family: $font-family_4;
//     font-size: 20px;
//     font-weight: 500;
//     left: 778px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 302px;
//     white-space: nowrap;
//     width: 174px;
//   }

//   .vector-wrapper {
//     background-color: $background-color_1;
//     border-radius: 36px;
//     height: 72px;
//     left: 199px;
//     position: absolute;
//     top: 127px;
//     width: 72px;
//   }

//   .vector-2 {
//     height: 37px;
//     left: 27px;
//     position: absolute;
//     top: 18px;
//     width: 18px;
//   }

//   .img-wrapper {
//     background-color: $background-color_1;
//     border-radius: 36px;
//     height: 72px;
//     left: 362px;
//     position: absolute;
//     top: 127px;
//     width: 72px;
//   }

//   .vector-3 {
//     height: 30px;
//     left: 21px;
//     position: absolute;
//     top: 20px;
//     width: 30px;
//   }

//   .overlap-7 {
//     background-color: $background-color_1;
//     border-radius: 36px;
//     height: 72px;
//     left: 280px;
//     position: absolute;
//     top: 127px;
//     width: 72px;
//   }

//   .vector-4 {
//     height: 32px;
//     left: 20px;
//     position: absolute;
//     top: 20px;
//     width: 33px;
//   }

//   .overlap-8 {
//     background-color: $background-color_1;
//     border-radius: 36px;
//     height: 72px;
//     left: 443px;
//     position: absolute;
//     top: 127px;
//     width: 72px;
//   }

//   .vector-5 {
//     height: 24px;
//     left: 19px;
//     position: absolute;
//     top: 24px;
//     width: 34px;
//   }

//   .TERMS-conditions {
//     color: $color_1;
//     font-family: $font-family_1;
//     font-size: 14px;
//     font-weight: 700;
//     height: 36px;
//     left: 127px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     top: 259px;
//     width: 388px;
//   }

//   .element-womanii-com-ALL {
//     height: 10px;
//     left: 1012px;
//     position: absolute;
//     top: 263px;
//     width: 309px;
//   }

//   .overlap-9 {
//     height: 34px;
//     left: 119px;
//     position: absolute;
//     top: 48px;
//     width: 461px;
//   }

//   .rectangle-7 {
//     background-color: $background-color_2;
//     height: 9px;
//     left: 65px;
//     position: absolute;
//     top: 25px;
//     width: 331px;
//   }

//   .text-wrapper-11 {
//     color: $color_1;
//     font-family: $font-family_1;
//     font-size: 34px;
//     font-weight: 700;
//     height: 33px;
//     left: 0;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 0;
//     white-space: nowrap;
//     width: 461px;
//   }

//   .overlap-10 {
//     height: 34px;
//     left: 698px;
//     position: absolute;
//     top: 44px;
//     width: 461px;
//   }

//   .rectangle-8 {
//     background-color: $background-color_4;
//     height: 9px;
//     left: 66px;
//     position: absolute;
//     top: 25px;
//     width: 331px;
//   }

//   .line {
//     height: 1px;
//     left: 120px;
//     object-fit: cover;
//     position: absolute;
//     top: 234px;
//     width: 1200px;
//   }

//   .group-3 {
//     height: 77px;
//     left: 929px;
//     position: absolute;
//     top: 121px;
//     width: 261px;
//   }

//   .group-4 {
//     height: 77px;
//     left: 645px;
//     position: absolute;
//     top: 121px;
//     width: 261px;
//   }

//   .overlap-11 {
//     background-color: $background-color_1;
//     border-radius: 28px;
//     height: 56px;
//     left: 1257px;
//     position: absolute;
//     top: 167px;
//     width: 56px;
//   }

//   .arrow-forward-ios {
//     height: 19px;
//     left: 19px;
//     position: absolute;
//     top: 9px;
//     width: 19px;
//   }

//   .top {
//     color: $color_7;
//     font-family: $font-family_1;
//     font-size: 13px;
//     font-weight: 500;
//     height: 16px;
//     left: 16px;
//     letter-spacing: 0;
//     line-height: normal;
//     position: absolute;
//     text-align: center;
//     top: 29px;
//   }
// }
// $color_1: #ffffff;
// $color_2: #1d1d1b;
// $color_3: #616161;
// $color_4: #e5097d;
// $color_5: #818181;
// $font-family_1: "Overpass", Helvetica;
// $font-family_2: "Inter", Helvetica;
// $background-color_1: #ffffff;
// $background-color_2: #e5097d;
// $background-color_3: #48277f;
// $background-color_4: #d9d9d938;
// $background-color_5: #e32975;
// $border-color_1: #00000036;
// $border-color_2: #d9d9d9;

// .new-password {
// 	background-color: $background-color_1;
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: center;
// 	width: 100%;
// 	.div {
// 		background-color: $background-color_1;
// 		height: 1000px;
// 		overflow: hidden;
// 		position: relative;
// 		width: 1440px;
// 	}
// 	.overlap {
// 		background-color: $background-color_2;
// 		height: 65px;
// 		left: 1px;
// 		position: absolute;
// 		top: 219px;
// 		width: 1441px;
// 	}
// 	.creators-baby-kids {
// 		color: $color_1;
// 		font-family: $font-family_1;
// 		font-size: 14px;
// 		font-weight: 700;
// 		height: 33px;
// 		left: 369px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 15px;
// 		width: 710px;
// 	}
// 	.overlap-group {
// 		background-color: $background-color_3;
// 		height: 52px;
// 		left: 0;
// 		position: absolute;
// 		top: 0;
// 		width: 1441px;
// 	}
// 	.HOME-ABOUT-LATEST {
// 		color: $color_1;
// 		font-family: $font-family_1;
// 		font-size: 16px;
// 		font-weight: 700;
// 		left: 121px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 19px;
// 		width: 381px;
// 	}
// 	.overlap-2 {
// 		height: 24px;
// 		left: 1112px;
// 		position: absolute;
// 		top: 17px;
// 		width: 209px;
// 	}
// 	.element {
// 		color: $color_1;
// 		font-family: $font-family_1;
// 		font-size: 16px;
// 		font-weight: 500;
// 		left: 0;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 1px;
// 	}
// 	.history {
// 		height: 24px;
// 		left: 126px;
// 		position: absolute;
// 		top: 0;
// 		width: 24px;
// 	}
// 	.calendar-month {
// 		height: 24px;
// 		left: 1080px;
// 		position: absolute;
// 		top: 17px;
// 		width: 24px;
// 	}
// 	.isolation-mode {
// 		height: 26px;
// 		left: 991px;
// 		position: absolute;
// 		top: 123px;
// 		width: 25px;
// 	}
// 	.overlap-3 {
// 		background-color: $background-color_3;
// 		border-radius: 3px;
// 		height: 49px;
// 		left: 1169px;
// 		position: absolute;
// 		top: 111px;
// 		width: 152px;
// 	}
// 	.create {
// 		color: $color_1;
// 		font-family: $font-family_1;
// 		font-size: 18px;
// 		font-weight: 400;
// 		height: 25px;
// 		left: 59px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 11px;
// 		width: 83px;
// 	}
// 	.img {
// 		height: 25px;
// 		left: 21px;
// 		position: absolute;
// 		top: 12px;
// 		width: 25px;
// 	}
// 	.sign-IN {
// 		color: $color_2;
// 		font-family: $font-family_1;
// 		font-size: 18px;
// 		font-weight: 400;
// 		height: 25px;
// 		left: 1072px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 122px;
// 		width: 83px;
// 	}
// 	.group {
// 		height: 25px;
// 		left: 1042px;
// 		position: absolute;
// 		top: 123px;
// 		width: 24px;
// 	}
// 	.overlap-4 {
// 		background-color: $background-color_3;
// 		border-radius: 58px;
// 		height: 56px;
// 		left: 121px;
// 		position: absolute;
// 		top: 108px;
// 		width: 166px;
// 	}
// 	.text-wrapper {
// 		color: $color_1;
// 		font-family: $font-family_1;
// 		font-size: 14px;
// 		font-weight: 500;
// 		height: 46px;
// 		left: 21px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 4px;
// 		width: 91px;
// 	}
// 	.vector {
// 		height: 8px;
// 		left: 130px;
// 		position: absolute;
// 		top: 32px;
// 		width: 12px;
// 	}
// 	.element-2 {
// 		height: 103px;
// 		left: 576px;
// 		object-fit: cover;
// 		position: absolute;
// 		top: 84px;
// 		width: 272px;
// 	}
// 	.overlap-5 {
// 		border: 1px solid;
// 		border-color: $border-color_1;
// 		height: 577px;
// 		left: 119px;
// 		position: absolute;
// 		top: 150px;
// 		width: 1202px;
// 	}
// 	.overlap-6 {
// 		height: 90px;
// 		left: 570px;
// 		position: absolute;
// 		top: 107px;
// 		width: 583px;
// 	}
// 	.p {
// 		color: $color_3;
// 		font-family: $font-family_2;
// 		font-size: 20px;
// 		font-weight: 500;
// 		left: 37px;
// 		letter-spacing: 0;
// 		line-height: 27.1px;
// 		position: absolute;
// 		text-align: center;
// 		top: 63px;
// 		width: 510px;
// 	}
// 	.text-wrapper-2 {
// 		color: $color_4;
// 		font-family: $font-family_1;
// 		font-size: 55px;
// 		font-weight: 600;
// 		left: 0;
// 		letter-spacing: -3.02px;
// 		line-height: 64.6px;
// 		position: absolute;
// 		text-align: center;
// 		top: 0;
// 		width: 583px;
// 	}
// 	.frame {
// 		height: 435px;
// 		left: 64px;
// 		position: absolute;
// 		top: 62px;
// 		width: 431px;
// 	}
// 	.div-wrapper {
// 		background-color: $background-color_4;
// 		border: 1px solid;
// 		border-color: $border-color_2;
// 		border-radius: 11px;
// 		height: 65px;
// 		left: 589px;
// 		position: absolute;
// 		top: 238px;
// 		width: 545px;
// 	}
// 	.text-wrapper-3 {
// 		color: $color_5;
// 		font-family: $font-family_2;
// 		font-size: 17px;
// 		font-weight: 400;
// 		left: 21px;
// 		letter-spacing: 0;
// 		line-height: 25px;
// 		position: absolute;
// 		top: 17px;
// 		white-space: nowrap;
// 		width: 506px;
// 	}
// 	.overlap-7 {
// 		background-color: $background-color_2;
// 		border: 1px solid;
// 		border-color: $border-color_2;
// 		border-radius: 11px;
// 		height: 65px;
// 		left: 589px;
// 		position: absolute;
// 		top: 342px;
// 		width: 545px;
// 	}
// 	.text-wrapper-4 {
// 		color: $color_1;
// 		font-family: $font-family_2;
// 		font-size: 20px;
// 		font-weight: 600;
// 		left: 193px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 19px;
// 		white-space: nowrap;
// 	}
// 	.overlap-group-2 {
// 		background-color: $background-color_3;
// 		height: 296px;
// 		left: 0;
// 		position: absolute;
// 		top: 1063px;
// 		width: 1440px;
// 	}
// 	.vector-wrapper {
// 		background-color: $background-color_1;
// 		border-radius: 36px;
// 		height: 72px;
// 		left: 199px;
// 		position: absolute;
// 		top: 127px;
// 		width: 72px;
// 	}
// 	.vector-2 {
// 		height: 37px;
// 		left: 27px;
// 		position: absolute;
// 		top: 18px;
// 		width: 18px;
// 	}
// 	.img-wrapper {
// 		background-color: $background-color_1;
// 		border-radius: 36px;
// 		height: 72px;
// 		left: 362px;
// 		position: absolute;
// 		top: 127px;
// 		width: 72px;
// 	}
// 	.vector-3 {
// 		height: 30px;
// 		left: 21px;
// 		position: absolute;
// 		top: 20px;
// 		width: 30px;
// 	}
// 	.overlap-8 {
// 		background-color: $background-color_1;
// 		border-radius: 36px;
// 		height: 72px;
// 		left: 280px;
// 		position: absolute;
// 		top: 127px;
// 		width: 72px;
// 	}
// 	.vector-4 {
// 		height: 32px;
// 		left: 20px;
// 		position: absolute;
// 		top: 20px;
// 		width: 33px;
// 	}
// 	.overlap-9 {
// 		background-color: $background-color_1;
// 		border-radius: 36px;
// 		height: 72px;
// 		left: 443px;
// 		position: absolute;
// 		top: 127px;
// 		width: 72px;
// 	}
// 	.vector-5 {
// 		height: 24px;
// 		left: 19px;
// 		position: absolute;
// 		top: 24px;
// 		width: 34px;
// 	}
// 	.TERMS-conditions {
// 		color: $color_1;
// 		font-family: $font-family_1;
// 		font-size: 14px;
// 		font-weight: 700;
// 		height: 36px;
// 		left: 127px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		top: 259px;
// 		width: 388px;
// 	}
// 	.element-womanii-com-ALL {
// 		height: 10px;
// 		left: 1012px;
// 		position: absolute;
// 		top: 263px;
// 		width: 309px;
// 	}
// 	.overlap-10 {
// 		height: 34px;
// 		left: 119px;
// 		position: absolute;
// 		top: 48px;
// 		width: 461px;
// 	}
// 	.rectangle {
// 		background-color: $background-color_2;
// 		height: 9px;
// 		left: 65px;
// 		position: absolute;
// 		top: 25px;
// 		width: 331px;
// 	}
// 	.text-wrapper-5 {
// 		color: $color_1;
// 		font-family: $font-family_1;
// 		font-size: 34px;
// 		font-weight: 700;
// 		height: 33px;
// 		left: 0;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		text-align: center;
// 		top: 0;
// 		white-space: nowrap;
// 		width: 461px;
// 	}
// 	.overlap-11 {
// 		height: 34px;
// 		left: 698px;
// 		position: absolute;
// 		top: 44px;
// 		width: 461px;
// 	}
// 	.rectangle-2 {
// 		background-color: $background-color_5;
// 		height: 9px;
// 		left: 66px;
// 		position: absolute;
// 		top: 25px;
// 		width: 331px;
// 	}
// 	.line {
// 		height: 1px;
// 		left: 120px;
// 		object-fit: cover;
// 		position: absolute;
// 		top: 234px;
// 		width: 1200px;
// 	}
// 	.group-2 {
// 		height: 77px;
// 		left: 929px;
// 		position: absolute;
// 		top: 121px;
// 		width: 261px;
// 	}
// 	.group-3 {
// 		height: 77px;
// 		left: 645px;
// 		position: absolute;
// 		top: 121px;
// 		width: 261px;
// 	}
// 	.overlap-12 {
// 		background-color: $background-color_1;
// 		border-radius: 28px;
// 		height: 56px;
// 		left: 1257px;
// 		position: absolute;
// 		top: 167px;
// 		width: 56px;
// 	}
// 	.arrow-forward-ios {
// 		height: 19px;
// 		left: 19px;
// 		position: absolute;
// 		top: 9px;
// 		width: 19px;
// 	}
// 	.top {
// 		color: $color_5;
// 		font-family: $font-family_1;
// 		font-size: 13px;
// 		font-weight: 500;
// 		height: 16px;
// 		left: 16px;
// 		letter-spacing: 0;
// 		line-height: normal;
// 		position: absolute;
// 		text-align: center;
// 		top: 29px;
// 	}
// }
/* CSS for card flip animation */

/* CSS for card flip animation and overlay */
/* CSS for card flip animation and overlay */
.card-container {
  perspective: 1000px;
  position: relative;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

// .card:hover {
//
// }

// .card:hover .card-overlay {
//   opacity: 1;
// }

.card-face {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-back {
  transform: rotateY(180deg);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.5); /* Red background with 50% opacity */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // opacity: 0;
  transition: opacity 0.3s;
  text-align: center;
}

.teamheading {
  color: #1d1d1b;
  text-align: center;
  font-family: Overpass;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.settings {
  /* Style the navigation tabs */
  .nav-tabs {
    background-color: #e5097d;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
  }

  .nav-tabs .nav-link {
    color: #ffffff; /* White text color for contrast */
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    margin: 0 10px;
    text-align: center;
    text-decoration: none; /* Remove underlines from links */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }

  .nav-link:hover {
    background-color: #48277f; /* Change color on hover */
  }

  .nav-link.active {
    background-color: #48277f;
  }

  @media (max-width: 768px) {
    .nav-tabs {
      justify-content: flex-start; /* Align tabs to the left on smaller screens */
    }
  }
}

#main {
  .my_account_sec {
    .form-control {
      height: 49px;
      color: #000;
    }
    .form-select {
      height: 49px;
      color: #000;
    }
    label {
      font-size: 14px;
    }
  }
}

.mid_withdraw {
  .form-select {
    width: 90%;
  }
}

#radio-buttons {
  height: 49px;
  align-items: center;
}

.tab-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.custom-radio {
  .form-check-input {
    font-size: 20px;
    border-color: #e5097d;

    &:checked {
      background-color: #e5097d;
      color: #fff;
      border-color: #e5097d;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

#change-password {
  .container {
    height: 500px;
  }
  button[type="submit"] {
    width: auto;
    height: auto;
    box-shadow: none;
    background-color: #48277f;
    border: 1px solid #48277f;
    border-radius: 25px;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    padding: 6px 25px;

    &:hover {
      transition: 0.5s all;
      background-color: #fff;
      color: #48277f;
      border-color: #48277f;
    }
  }
}

.form-control-error {
  border: 1px solid rgb(236, 36, 36);
  &::placeholder {
    color: rgb(236, 36, 36);
  }
  &:focus {
    box-shadow: none;
    border: 1px solid rgb(236, 36, 36);
  }
}
// input {
//   &:focus {
//     box-shadow: none !important;
//     border-color: transparent !important;
//     outline: 0 !important;
//   }
// }
.form-label-error {
  color: rgb(236, 36, 36);
}
.error-message {
  color: rgb(236, 36, 36);
}
.form-check-input:checked {
  background-color: #e5097d;
  border-color: #e5097d;
}

#imageUploadingErrors {
  position: absolute;
}
.dashboard #imageUploadingErrors {
  position: static;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  margin-left: 5px; /* Add spacing between the ">" separator and the next item */
  color: #e5097d; /* Define the color of the separator */
}

#share-modal {
  .modal-header {
    border: none;
    .btn-close {
      border: none;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
  .modal-body {
    .modal-title {
      h1 {
        font-size: 25px;
        font-family: " Editors-Note";
      }
    }
    .earn {
      .btn-default {
        i {
          padding-right: 10px;
        }
      }
      h5 {
        text-align: center;

        font-weight: 600;
        font-size: 25px;
        font-family: "Overpass-Medium";
      }

      p {
        font-size: 14px;
      }

      .row {
        .d-flex {
          justify-content: center;

          .bordr {
            padding-left: 10px;
            background: #faf5f1;
            padding: 22px 14px 11px 1px;
            align-self: center;

            span {
              font-size: 30px;
              font-weight: 100;
              color: #e5097d;
            }

            a {
              img {
                width: 70%;
              }
            }
          }

          .align-self-center {
            background: #faf5f1;
            padding: 3px 3px 10px 14px;

            .m-0 {
              font-size: 18px;

              font-family: "Noto Serif", serif;
              font-weight: 400;
              color: #1d1d1b;
            }

            h6 {
              font-size: 26px;
              font-weight: 600;
              font-family: "Noto Serif", serif;
            }
          }
        }

        ul {
          list-style: none;

          li {
            position: relative;
            padding: 10px 0 50px 20px;
            font-size: 13px;
            padding-left: 28px;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10px;
              width: 1px;
              height: 40px;
              background-color: #e5097d;
            }

            &::before {
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #e5097d;
              position: absolute;
              left: 0;
              top: 10px;
            }
          }
        }
      }

      .lastt {
        ul {
          list-style: none;

          li {
            position: relative;
            padding: 10px 0 30px 20px;
            font-size: 20px;
            font-weight: 400;
            padding-left: 28px;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10px;
              width: 1px;
              height: 40px;
            }

            &::before {
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #e5097d;
              position: absolute;
              left: 0;
              top: 10px;
            }
          }
        }
      }
    }
  }
  .main {
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      h2 {
        font-size: 20px;
        margin: 0;
        padding: 0 30px;
        font-family: " Editors-Note";

        background-color: white;
        z-index: 100000;
        width: fit-content;
      }
    }
  }
  .icon-text {
    padding-bottom: 30px;
    h3 {
      font-size: 25px;
      font-family: " Editors-Note";
    }
  }
  .icon-text {
    .icons {
      a {
        align-self: center;
        text-align: center;
        .fa-facebook-f {
          background: #3b5998;
          border-radius: 50px;
          width: 38px;
          height: 38px;
          padding: 10px;
          text-align: center;
          margin: 5px;
          color: #fff;
        }
        img {
          width: 72px;
          margin: -15px;
        }
        .fa-linkedin-in {
          background: #1178b3;
          border-radius: 50px;
          width: 38px;
          height: 38px;
          padding: 10px;
          text-align: center;
          color: #fff;
          margin: 3px;
        }
        .fa-whatsapp {
          background: #0cc20c;
          border-radius: 50px;
          width: 38px;
          height: 38px;
          padding: 10px;
          text-align: center;
          color: #fff;
          margin: 3px;
        }
        svg {
          background: black;
          border-radius: 50px;
          width: 19px;
          height: 19px;
          padding: 10px;
          margin-top: 7px;
          margin-left: 1px;
          margin-right: 4px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

#language-modal {
  // width: max-content;

  .modal-header {
    border: none;
    .btn-close {
      border: none;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .modal-footer {
    border: none;
    margin-bottom: 15px;
  }

  .modal-footer {
    // .btn-secondary {
    //   border-radius: 73px;
    //   width: 40%;
    //   text-align: center;
    //   border: 1px solid #828282;

    //   background: #ffffff;
    //   color: #828282;
    //   font-family: Inter;
    // }

    // .btn-primary {
    //   border-radius: 73px;
    //   width: 40%;
    //   text-align: center;
    //   border: 1px solid #e5097d;
    //   background: #e5097d;
    //   color: #fff;
    //   font-family: Inter;
    // }
  }

  .form-check {
    // padding-top: 6px;
    // padding-left: 88px;
    .form-check-label {
      color: #222;
      font-family: Inter;
      font-weight: 400;
      font-size: 18px;
      padding: 0;
    }
    .form-check-input {
      font-size: 20px;
      border-color: #e5097d;
      &:checked {
        background-color: #e5097d;
        color: #fff;
        border-color: #e5097d;
      }
      &:focus {
        box-shadow: none;
      }
    }

    .modal-dialog {
      justify-content: center;
    }
  }
  //   @media(max-width:991.98px){
  // width: auto;
  //   }
}

#collection-modal {
  .modal-header {
    border: none;
    .btn-close {
      border: none;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .modal-content {
    border-radius: 16px;
    padding: 10px;
  }

  .modal-body {
    h1 {
      font-size: 26px;
      font-family: " Editors-Note";
    }
    h3 {
      font-size: 16px;
      font-family: Inter;
      font-weight: 400;
    }

    p {
      border-radius: 9px 9px 0px 0px;
      background: #eaf1f3;
      padding: 9px 10px 5px 10px;
      font-size: 16px;
      font-family: " Editors-Note";

      padding: 10px;
      width: 90%;
    }
  }

  .modal-footer {
    border: none;
    justify-content: center;
    margin-bottom: 27px;

    .btn-primary {
      border: none;
      border-radius: 73px;
      background: #e5097d;
      color: #fff;
      padding: 15px 58px 15px 58px;
      font-size: 12px;
    }
  }

  .form {
    .form-control {
      border-radius: 9px 9px 0px 0px;
      background: #eaf1f3;
      font-family: " Editors-Note";

      width: 100%;
      padding: 9px 8px 10px 8px;
    }
  }
}

.modal-show-btn {
  width: fit-content;
  height: fit-content;
}
#download-app-modal {
  .modal-content {
    border-radius: 25px;
  }
  .modal-title {
    text-align: center;
    padding: 45px 20px 0 20px;
    h5 {
      color: #1d1d1b;
      font-family: " Editors-Note";
      text-align: center;
      font-size: 27px;
      font-weight: 400;
    }
  }

  .modal-footer {
    text-align: center;
    display: block;
    padding: 10px 20px 45px 20px;
    .btn-primary {
      width: 200px;
      padding: 10px 20px;
      border-radius: 73px;
      background: #e5097d;
      border: none;
      text-align: center;
      color: #fff;
      a {
        margin: 0;
      }
    }
  }
}
.modal-footer {
  border: 0;
  outline: none;
}
#report-modal {
  .modal-content {
    border-radius: 25px;
  }
  .modal-header {
    border: 0;
    outline: none;
    align-items: center;
    padding: 30px 20px 20px 20px;
    .modal-title {
      display: flex;
      justify-content: flex-end;
      h5 {
        margin: 0;
        color: #1d1d1b;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 10px;
      }
      .title-line {
        border-bottom: 1px solid #e32975;
        width: 70%;
        text-align: center;
      }
    }
    .modal-close-button {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px;
    }
  }
  .modal-body {
    .modal-radio-heading {
      h5 {
        padding: 5px 20px;
        color: #222;
        font-size: 25px;
        font-weight: 600;
      }
    }
    .modal-radio {
      .form-check-input {
        border-color: #e32975;
        &:checked {
          background-color: #e32975;
          border-color: #e32975;
        }
        &:focus {
          box-shadow: none;
        }
      }
      padding: 0px 20px 28px 20px;
      input {
        border: 1px solid #e32975;
        width: 20px;
        height: 20px;
      }
      label {
        color: #222;
        font-size: 20px;
        padding-left: 10px;
        padding-bottom: 5px;
        font-weight: 400;
      }
    }
  }
}
@media (max-width: 576.98px) {
  #root #main .forget-password .bod .forget {
    font-size: 23px;
    margin-top: 20px;
  }
  body {
    .challenge_live.badge {
      font-size: 7px;
    }
  }
  #header .mid-header ul li a {
    font-size: 14px;
    padding: 6px 12px;

    width: max-content;
  }
  #main {
    .modal-header {
      .modal-title {
        h5 {
          font-size: 22px;
        }
      }
    }
    .modal-body {
      .modal-radio-heading {
        h5 {
          font-size: 22px;
        }
      }
    }

    .modal-radio {
      label {
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 425.98px) {
  #main {
    .modal-header {
      .modal-title {
        h5 {
          font-size: 20px;
        }
      }
    }
    .modal-body {
      .modal-radio-heading {
        h5 {
          font-size: 20px;
        }
      }
    }

    .modal-radio {
      label {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 375.98px) {
  #listing_main .listing_sec .tab-pane .card .card-body h5 a {
    font-size: 16px;
  }
  #main {
    .modal-header {
      .modal-title {
        h5 {
          font-size: 18px;
        }
      }
    }
    .modal-body {
      .modal-radio-heading {
        h5 {
          font-size: 18px;
        }
      }
    }

    .modal-radio {
      label {
        font-size: 15px;
      }
    }
  }
}

#menu-modal {
  .modal-content {
    border-radius: 25px;
    background: #f9f9f9;
    width: 100%;
  }

  .modal-header {
    border: 0;
    outline: none;
    padding: 16px 0;
    .modal-title {
      display: flex;
      justify-content: flex-end;
      h5 {
        margin: 0;
        color: #1d1d1b;
        font-size: 25px;
        font-weight: 400;
        border-bottom: 1px solid #e32975;
        padding-bottom: 10px;
        border-spacing: 50px;
      }
    }
    .modal-close-button {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px;
    }
  }
  .modal-body {
    .col-6 {
      padding: 10px;
    }
    .col-lg-6 {
      padding: 10px 10px 10px 10px;
    }
    .col-lg-12 {
      padding: 10px 10px 10px 10px;
    }
    .card {
      border: 0;
      outline: 0;
      border-radius: 7px;
      background: #fff;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.01);
      .card-body {
        .card-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          .card-svg {
            // background-color: #e32975;
            border-radius: 50px;
            height: 60px;
            width: 60px;
            // padding: 10px;
            svg {
              height: 40px;
              width: 40px;
            }
          }
        }
        .card-title {
          text-align: center;
          padding: 20px 0 0px 0;
          a {
            font-weight: 700;
            font-size: 14px;
            text-decoration: none;
            color: #000;
          }
        }
      }
    }
  }
}

.searchFormPublic .input-group input[type="search"]:focus,
.searchFormPublic .input-group input[type="search"]:active,
.searchFormPublic .input-group .input-group-text .btn[type="submit"]:focus,
.searchFormPublic .input-group .input-group-text .btn[type="submit"]:active {
  box-shadow: none;
  outline: 0;
  font-size: 18px;
}

.searchFormPublic .input-group .input-group-text .btn[type="submit"]:hover {
  background-color: #e5097d;
  color: #48267f;
}
.searchFormPublic .input-group .input-group-text .btn[type="submit"] {
  border-top-left-radius: 0;
  border: 0;
  border-bottom-left-radius: 0;
  font-size: 20px;
}

.searchFormPublic .input-group input[type="search"] {
  border: 0;
  border-radius: 15px;
}

.searchFormPublic .input-group {
  border: 1px solid #e5097d;
  border-radius: 15px;
}

.searchFormPublic .input-group .input-group-text {
  background-color: transparent;
  border-top-left-radius: 15px;
  border: 0;
  border-bottom-left-radius: 15px;
  color: #e5097d;
}
.offcanvas.offcanvas-top {
  height: auto;
}
.react-datetime-picker {
  width: 100%;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

.attachmetsPreview {
  display: flex;
  flex-direction: column;
  // overflow-y: scroll;
  width: 100%;
  gap: 20px;
 img{
    height: 200px;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e5097d;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid #fff;
    background: #fff;
  }
}
#main .trending_sec #trendingPostTabs,
#main_2 .trending_sec #trendingPostTabs {
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e5097d;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid #fff;
    background: #fff;
  }
}

#main .upload-video .css-b62m3t-container {
  font-size: 23px;
  color: rgba(29, 29, 27, 0.6392156863);
  height: 70px;
  width: 100%;
}
#main .upload-video .css-3iigni-container {
  font-size: 23px;
  color: rgba(29, 29, 27, 0.6392156863);
  height: 70px;
  width: 100%;
}
#main .upload-video .css-3iigni-container > div:not(.css-1nmdiq5-menu) {
  height: 100%;
  // background-color: #eaf1f3;
}

#main .upload-video .css-b62m3t-container > div:not(.css-1nmdiq5-menu) {
  height: 100%;
  background-color: #eaf1f3;
}
#main .upload-video .public-DraftEditorPlaceholder-root {
  color: #1d1d1ba3;
}
.clearAudioFile {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #e5097d;
  border-color: #e5097d;
  font-size: 20px;
}

.clearVideoFile {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #e5097d;
  border-color: #e5097d;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.previousPostCover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.previousPostCover .previousPost {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
}

.previousPostCover .previousPost .img-cover {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #e5097d;
}

.previousPostCover .previousPost .img-cover img {
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transition: 0.5s all;
  &:hover {
    transition: 0.5s all;
    transform: scale(1.5);
  }
}

.previousPostCover .previousPost h3 {
  /* flex-grow: 1; */
  text-transform: capitalize;
  font-size: 20px;
  margin: 0;
  font-family: "Noto Serif", serif;
  a {
    text-decoration: none;
    color: #000;
    transition: 0.5s all;
    &:hover {
      transition: 0.5s all;
      color: #e5097d;
    }
  }
}

.nextPostCover {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.nextPostCover .nextPost {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.nextPostCover .nextPost .img-cover {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #e5097d;
}

.nextPostCover .nextPost .img-cover img {
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transition: 0.5s all;
  &:hover {
    transition: 0.5s all;
    transform: scale(1.5);
  }
}

.nextPostCover .nextPost h3 {
  /* flex-grow: 1; */
  text-transform: capitalize;
  font-size: 20px;
  margin: 0;
  font-family: "Noto Serif", serif;
  a {
    text-decoration: none;
    color: #000;
    transition: 0.5s all;
    &:hover {
      transition: 0.5s all;
      color: #e5097d;
    }
  }
}

.nextPeriviousPostsSection .row {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}
.nextPeriviousPostsSection .row .col-lg-6:first-child {
  position: relative;
}
.nextPeriviousPostsSection .row .col-lg-6:first-child:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.14);
}
.nextPeriviousPostsSection .btn-default-2 {
  padding: 10px 30px;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: 0.5s all;
}

.bg-treitory {
  background-color: #fff0f7 !important;
}

.btn-default-2.addToBookmarkButton {
  height: 40px;
  width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 16px;
  background-color: #ffffff63;
  color: #e5097d;
  position: absolute;
  top: 10px;
  right: 10px;

  z-index: 1;
  
}

section.topContributorsSection .card img {
  height: 85px;
  width: 85px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #e5097d;
}
section.topContributorsSection .card .userInfo {
  flex-grow: 1;
}
#main .topContributorsSection p {
  margin: 0;
}

section.topContributorsSection .card h5.card-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  a {
    text-decoration: none;
    color: #000;
    &:hover {
      color: #e5097d;
    }
  }
}
section.topContributorsSection .card .userStats {
  font-size: 19px;
  color: #e5097d;
}

section.topContributorsSection .card .btn-default-2 {
  padding: 10px 30px;
}
section.userProfileSection #listing_main .listing_sec ul li a img {
  height: 40px;
}

.select_background .nav-tabs .nav-item .nav-link,
.select_background .nav-tabs .nav-link {
  background-color: #f5f5f5;
  color: #828282;
  border-radius: 0;
  flex: 1;
  justify-content: center;
  display: flex;
  height: 64px;
  align-items: center;
}
.select_background .nav-tabs .nav-item.show .nav-link,
.select_background .nav-tabs .nav-item .nav-link.active {
  background-color: #48277f;
  color: white;
  border-radius: 0;
}

.select_background .tab-content {
  background-color: #eaf1f3;
  padding: 17px;
}
.select_background .tab-content #scrollableDiv {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e5097d;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid #fff;
    background: #fff;
  }
}
.select_background .backgroundsToSelect img {
  border-radius: 5px;
}

.author_info img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #e5097d;
}

.post_info_cover {
  .post_info {
    span {
      font-size: 20px;
    }
  }
  ul {
    li {
      a {
        font-size: 20px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #fff;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.comment button.dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #888;
  font-size: 20px;
}
.comment button.dropdown-toggle:hover,
.comment button.dropdown-toggle:focus,
.comment button.dropdown-toggle:active {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
}
.comment button.dropdown-toggle:after {
  display: none;
}

#main .upload-video textarea {
  // background-color: #eaf1f3 !important;
  // border-radius: 5px;
  // color: rgba(29, 29, 27, 0.639) !important;
  // font-size: 18px !important;
  // padding: 17px !important;
  // position: relative !important;
  height: 200px !important;
  // resize: auto !important;
  // top: auto !important;
  // left: auto !important;
  // bottom: auto !important;
  border-radius: 5px;
  color: rgba(29, 29, 27, 0.639) !important;
  font-size: 18px !important;
  padding: 0 6px !important;
}

div#combinedEditor div:not(.d-none) > div > div {
  // display:none;
  border-radius: 5px;
  color: transparent !important;
  font-size: 18px !important;
  padding: 0 6px !important;
  height: 200px !important;
}

div#combinedEditor div:not(.d-none) > div > div strong {
  // display:none;
  font-weight: 700px;
  color: transparent !important;
}

div[data-testid="otp-input-root"] input {
  height: 67.58px !important;
  width: 72.5px !important;
  border: 1px solid rgba(167, 167, 167, 1);
  border-radius: 5px;
  margin: 0 !important;
}

div[data-testid="otp-input-root"] {
  gap: 5px;
}

@media (max-width: 991.98px) {
  div[data-testid="otp-input-root"] input {
    height: 40px !important;
    width: 40px !important;
    border: 1px solid rgba(167, 167, 167, 1);
    border-radius: 5px;
    margin: 0 !important;
  }
  div[data-testid="otp-input-root"] {
    justify-content: center;
  }
  #main .forget-password .bod .forget {
    font-size: 30px;
  }
}

.card_style_3 .card-img {
  height: 345px;
  width: 100%;
  // object-fit: cover;
}
@media (max-width: 575.98px) {
}

.popular_sec .card .card-img {
  // width: 100% !important;
  object-fit: contain;
  height: 270px;
  border-radius: 0;
  border: 2px solid #e62984;
}
.richEditor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  // margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.liveStreamVideoContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.liveStreamVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.modal-content {
  border: 0;
}

#menu-modal .startUPpopup p,
#menu-modal .startUPpopup p span {
  line-height: inherit;
  background-color: transparent !important;
}

.welcome-popup-backdrop {
  background-image: url("../img/6k2.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.my_account_sec {
  .react-date-picker__wrapper {
    border: 0 !important;
  }

  .react-date-picker__clear-button {
    display: none !important;
  }
}

.singlePostBanner .react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-color: #fff0f7;
}

@media (min-width: 767.98px) {
  .singlePostBanner {
    img.postBannerImage {
      height: 100vh;
      object-fit: contain;
      background-color: #fff0f7;
      background-blend-mode: overlay;
    }
  }
}

.reactImageCroper {
  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    /* height: 100vh; */
    /* width: 100%; */
  }
  .controls_cover {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
  }
  .controls {
    //
    // height: 40px;
    display: flex;
    align-items: start;
    gap: 10px;
    // flex-direction: column;
    label {
      display: inline-flex;
      flex-grow: 1;
    }
  }

  .slider {
    padding: 22px 0px;
  }

  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 13px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #3f51b5;
    width: 100%;
    margin: 0;
  }

  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .controls:hover input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
}

.challengeImage {
  height: 270px;
  object-fit: contain;
  box-shadow: 0px 0px 0 2px #e5257d;
  &.v2 {
    box-shadow: none;
    border: 2px solid #e5257d;
  }
}
.swiper-slide {
  padding: 2px;
}

.Blink {
  animation: blinker 0.3s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.rdw-dropdown-selectedtext[title="Font"],
.rdw-fontfamily-wrapper {
  display: none !important;
}

.rdw-option-disabled[title="Indent"],
.rdw-option-disabled[title="Outdent"] {
  display: none;
}
.form_add_user label.form-label {
  display: block !important;
}

.swiper-slide {
  /* padding: 0 15px; */
  box-sizing: border-box;
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  // transition: 0s all !important;
}
svg[color="#e5097d"] {
  color: #e5097d !important;
}

.rdw-editor-main .public-DraftStyleDefault-ltr span[data-mention] {
  color: blue;
  font-weight: bold;
}

.rdw-editor-main .public-DraftStyleDefault-ltr span[data-hashtag] {
  color: #007bff; /* Customize your color */
  font-weight: bold;
}
button[aria-label="PASTE-BUTTON"] {
  margin: 0;
  height: 20px;
  padding: 5px 10px;
  color: #fff;
  background-color: #e4257d;
  border-radius: 2px;
}
.offcanvas.offcanvas-bottom {
  height: auto;
}

.blog_2 {
  padding: 20px 0;
  background-color: #fff0f7;
  .sec_contant {
    .hin {
      font-size: 35px;
      font-weight: 400;
      font-family: " Editors-Note";
    }
  }

  .date {
    p {
      font-size: 16px;
    }

    .space {
      padding: 0 6px;
    }
  }
}

.mid4 {
  padding-bottom: 10px;

  .container {
    display: flex;
    justify-content: center;
  }

  .btn-default {
    font-family: "Overpass-Medium";
    background-color: #e5097d;
    // padding: 17px 63px;
    font-size: 1.2rem;
    border-radius: 55px;
    color: #fff;
    border: 0;
  }

  .tint {
    padding: 0px 10px 0 0;

    a {
      text-decoration: none;
      border: 1px solid #d9d9d9;
      color: black;
      padding: 9px 20px;
      border-radius: 18px;
    }
  }
}
.whatsAppCover {
  position: fixed;
  right: 5px;
  width: 65px;
  height: 65px;
  // background-color: #48277f;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  span {
    display: none;
    text-align: center;
    transition: 0.5s all;
    color: #000;
  }
  &:hover {
    span {
      display: block;
      transition: 0.5s all;
    }
  }
  @media (max-width: 575.98px) {
    width: 50px;
    height: 50px;

    span {
      display: block;
      transition: 0.5s all;
    }
  }
}
.btn-search {
  height: 100% !important;
  width: 50px !important;
  font-size: 20px !important;
}
.post_title,.card-title,.postPreview {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.swiper-pagination{
position: relative !important;
margin-top:10px;
}
.swiper-pagination-bullet-active{
  background:#e5097d !important;
}



.ql-mention-list-container {
  width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}

div#quill-toolbar,.editor-container {
    background-color: #fff;
}

.swiper-button-prev , .swiper-button-next {
    background-color: #e5097d;
    padding:10px !important;
    width: 40px  !important;
    height: 40px  !important;
    border-radius:50%;
    color: #fff !important;
    border: 1px solid #fff !important;
}
.swiper-button-prev:after , .swiper-button-next:after {
    font-size: 20px  !important;
}

.ql-editor {
  min-height: 100px;
}